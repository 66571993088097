import {
  FETCH_ALLBOOKINGS_REQUEST,
  FETCH_ALLBOOKINGS_SUCCESS,
  FETCH_ALLBOOKINGS_ERROR,
  FETCH_PARTICULAR_BOOKING_REQUEST,
  FETCH_PARTICULAR_BOOKING_SUCCESS,
  FETCH_PARTICULAR_BOOKING_ERROR
} from './BookingListConstants'

const intialstate = {
  all_booking_loading : false,
  all_booking : [],
  all_booking_error : '',
  all_booking_success : false,
  particular_booking_loading : false,
  particular_booking : [],
  particular_booking_error : '',
  particular_booking_success : false
}

const BookingListReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_ALLBOOKINGS_REQUEST : return{
      ...state,
      all_booking_loading:true
    }
    case FETCH_ALLBOOKINGS_SUCCESS : return{
      ...state,
      all_booking_loading:false,
      all_booking_success:true,
      all_booking:action.payload
    }
    case FETCH_ALLBOOKINGS_ERROR : return{
      ...state,
      all_booking_error:action.payload
    }
    case FETCH_PARTICULAR_BOOKING_REQUEST : return{
      ...state,
      particular_booking_loading:true
    }
    case FETCH_PARTICULAR_BOOKING_SUCCESS : return{
      ...state,
      particular_booking_loading:false,
      particular_booking_success:true,
      particular_booking:action.payload
    }
    case FETCH_PARTICULAR_BOOKING_ERROR : return{
      ...state,
      particular_booking_error:action.payload
    }
    default: return state
  }

}

export default BookingListReducers
