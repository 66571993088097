import React from 'react'
import '../../Styles/index.css';

function AdminFooter(){
  return(
    <div className="copy">
        <div className="container">
          <div className="copy-wrapper text-center">
            <p>© 2023 DineReserve™ All Rights Reserved </p>
          </div>
        </div>
      </div>
  )
}

export default AdminFooter
