import React from 'react';
import './App.css';
import TransparentHeader from './Components/TransparentHeader'
import SearchForm from './Components/SearchForm'
import HomeFeature2 from './Components/HomeFeature2'
import HomeWhy from './Components/HomeWhy'
import HeaderSlider from './Components/HeaderSlider'
import HomeNews from './Components/HomeNews'
import HomeOccasion from './Components/HomeOccasion'
import Footer from './Components/Footer'
import FooterBottom from './Components/FooterBottom'

function App() {
  return (
    <div className="App">

          <TransparentHeader />
          <HeaderSlider />
          <SearchForm />
          <HomeFeature2 />
          {
            // <HomeFeature2 />
          }
          <HomeWhy />
          <HomeNews />
          <Footer />
          <FooterBottom />
    </div>
  );
}

export default App;
