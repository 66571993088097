import {
  ADD_COUPON_REQUEST,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_ERROR
} from './AddCouponConstants'

const intialstate = {
  loading : false,
  Coupon_success_info : [],
  error : '',
  success : false
}

const AddCouponReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ADD_COUPON_REQUEST : return{
      ...state,
      loading:true
    }
    case ADD_COUPON_SUCCESS : return{
      ...state,
      loading:false,
      success:true,
      Coupon_success_info:action.payload
    }
    case ADD_COUPON_ERROR : return{
      ...state,
      error:action.payload
    }
    default: return state
  }

}

export default AddCouponReducers
