import {
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_ERROR,
  APPROVE_RESTAURANT_REQUEST,
  APPROVE_RESTAURANT_SUCCESS,
  APPROVE_RESTAURANT_ERROR
} from './RestaurantListConstants'
import {config} from '../../config'

export const updateRestaurantRequest = (data) =>{
  return{
    type : UPDATE_RESTAURANT_REQUEST,
    payload : data
  }
}

export const updateRestaurantSuccess = (update_restaurant_success) =>{
  return{
    type : UPDATE_RESTAURANT_SUCCESS,
    payload : update_restaurant_success
  }
}

export const updateRestaurantFaliure = (error) =>{
  return{
    type : UPDATE_RESTAURANT_ERROR,
    payload : error
  }
}


export const approveRestaurantRequest = (data) =>{
  return{
    type : APPROVE_RESTAURANT_REQUEST,
    payload : data
  }
}

export const approveRestaurantSuccess = (update_restaurant_success) =>{
  return{
    type : APPROVE_RESTAURANT_SUCCESS,
    payload : update_restaurant_success
  }
}

export const approveRestaurantFaliure = (error) =>{
  return{
    type : APPROVE_RESTAURANT_ERROR,
    payload : error
  }
}

export const updateRestaurant = (data) =>{
    return(dispatch) => {
      dispatch(updateRestaurantRequest(data))
      const url =`${config.api_root}/restaurantupdate/${data.restaurant_id}`;
      const request_option = {
      method: "POST",
      body: JSON.stringify({
                restaurant_email: data.restaurant_email_id,
                restaurant_name: data.restaurant_name,
                restaurant_phone : data.restaurant_phone,
                per_head_fees: data.per_head_fees,
                processing_fees:data.processing_fees,
                payment_option :data.payment_option,
                booking_option : data.booking_option,
                restaurant_coupon_status:data.restaurant_coupon_status,
                restaurant_description:data.restaurant_description,
                restaurant_rating:data.restaurant_rating,
                restaurant_review:data.restaurant_review,
                restaurant_cuisines:data.restaurant_cuisines,
                restaurant_address:data.restaurant_address,
                menu_url:data.menu_url,
                logo_image:data.logo_image,
                banner_image:data.banner_image,
                restaurant_opening_hours:data.restaurant_opening_hours,
                restaurant_closing_hours:data.restaurant_closing_hours,
                restaurant_extra_information:data.restaurant_extra_information,
                restaurant_slug:data.restaurant_slug
              }),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(restaurant_res =>{
      const update_restaurant_success = restaurant_res
      dispatch(updateRestaurantSuccess(update_restaurant_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(updateRestaurantFaliure(errorMsg))
    })
    }
}


export const approveRestaurant = (restid) =>{
    return(dispatch) => {
      dispatch(approveRestaurantRequest(restid))
      const url =`${config.api_root}/restaurantApprove/${restid}`;
      const request_option = {
      method: "POST",
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(restaurant_res =>{
      const update_restaurant_success = restaurant_res
      dispatch(approveRestaurantSuccess(update_restaurant_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(approveRestaurantFaliure(errorMsg))
    })
    }
}

export const deleteRestaurant = (restid) =>{
    return(dispatch) => {
      dispatch(approveRestaurantRequest(restid))
      const url =`${config.api_root}/restaurantDelete/${restid}`;
      const request_option = {
      method: "POST",
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(restaurant_res =>{
      const update_restaurant_success = restaurant_res
      //dispatch(approveRestaurantSuccess(update_restaurant_success))
      const timer = setTimeout(() => {
          window.location.reload();
      }, 1000);
    })
    .catch(error => {
      const errorMsg = error
      dispatch(approveRestaurantFaliure(errorMsg))
    })
    }
}
