import {
  FETCH_ALLBOOKINGS_REQUEST,
  FETCH_ALLBOOKINGS_SUCCESS,
  FETCH_ALLBOOKINGS_ERROR,
  FETCH_PARTICULAR_BOOKING_REQUEST,
  FETCH_PARTICULAR_BOOKING_SUCCESS,
  FETCH_PARTICULAR_BOOKING_ERROR
} from './BookingListConstants'
import {config} from '../../config'

export const fetchAllBookingsRequest = () =>{
  return{
    type : FETCH_ALLBOOKINGS_REQUEST
  }
}

export const fetchAllBookingsSuccess = (all_bookings_success) =>{
  return{
    type : FETCH_ALLBOOKINGS_SUCCESS,
    payload : all_bookings_success
  }
}

export const fetchAllBookingsFaliure = (error) =>{
  return{
    type : FETCH_ALLBOOKINGS_ERROR,
    payload : error
  }
}

export const fetchAllBookings = () =>{
    return(dispatch) => {
      dispatch(fetchAllBookingsRequest())
      const url =`${config.api_root}/all-booking`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(bookings_res =>{
      const all_bookings_success = bookings_res
      dispatch(fetchAllBookingsSuccess(all_bookings_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchAllBookingsFaliure(errorMsg))
    })
    }
}

export const fetchParticularBookingsRequest = (res_id) =>{
  return{
    type : FETCH_PARTICULAR_BOOKING_REQUEST,
    payload : res_id
  }
}

export const fetchParticularBookingsSuccess = (particular_booking_success) =>{
  return{
    type : FETCH_PARTICULAR_BOOKING_SUCCESS,
    payload : particular_booking_success
  }
}

export const fetchParticularBookingsFaliure = (error) =>{
  return{
    type : FETCH_PARTICULAR_BOOKING_ERROR,
    payload : error
  }
}

export const fetchParticularBooking = (res_id) =>{
    return(dispatch) => {
      dispatch(fetchParticularBookingsRequest(res_id))
      const url =`${config.api_root}/restaurant-bookings/${res_id}`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(booking_res =>{
      const particular_booking_success = booking_res
      dispatch(fetchParticularBookingsSuccess(particular_booking_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchParticularBookingsFaliure(errorMsg))
    })
    }
}
