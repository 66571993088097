import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './Redux/store';
import { Provider } from 'react-redux';
import App from './App';
import SearchResult from './Components/SearchResult'
import About from './Components/About'
import ContactUs from './Components/ContactUs'
import Restaurants from './Components/Restaurants'
import BookTable from './Components/BookTable'
import AdminLogin from './Components/Admin/AdminLogin'
import AdminSignup from './Components/Admin/AdminSignup'
import Restaurantlist from './Components/Admin/Restaurantlist'
import BookingList from './Components/Admin/BookingList'
import AddCoupon from './Components/Admin/AddCoupon'
import UpdateCoupon from './Components/Admin/UpdateCoupon'
import ManageCoupons from './Components/Admin/ManageCoupons'
import UpdateOrder from './Components/UpdateOrder';
import BookingTracker from './Components/BookingTracker';
import SignUp from './Components/SignUp';
import TermAndCondition from './Components/termandcondition';
//import TimingUpdate from './Components/Admin/TimingUpdate';





function Root(){
  return(
    <Provider store={store}>
        <Router>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route  path="/search" component={SearchResult} />
                    <Route  path="/about" component={About} />
                    <Route  path="/contactus" component={ContactUs} />
                    <Route  path="/termandcondition" component={TermAndCondition} />
                    <Route  path="/restaurants" component={Restaurants} />
                    <Route  path="/booktable/:id" component={BookTable} />
                    <Route  path="/admin/admin_login" component={AdminLogin} />
                    <Route  path="/admin/admin_signup" component={AdminSignup} />
                    <Route  path="/admin/restaurantlist" component={Restaurantlist} />
                    {/*<Route  path="/admin/timingupdate" component={TimingUpdate} /> */}
                    <Route  path="/admin/bookinglist" component={BookingList} />
                    <Route  path="/admin/addcoupon" component={AddCoupon} />
                    <Route  path="/admin/updatecoupon" component={UpdateCoupon} />
                    <Route  path="/admin/managecoupons" component={ManageCoupons} />
                    <Route path="/updateorder" component={UpdateOrder} />
                    <Route path="/bookingtracker" component={BookingTracker} />
                    <Route path="/signup" component={SignUp} />

                </Switch>
        </Router>
    </Provider>
  )
}

export default Root;
