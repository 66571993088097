import React ,{useEffect,useState,useMemo} from 'react'
import Header from './Header'
import Footer from './Footer'
import FooterBottom from './FooterBottom'
import StripeCheckout from "react-stripe-checkout";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { Redirect } from "react-router-dom";
import SearchForm from './SearchForm'
import {
        Link,
        useParams
      } from "react-router-dom";

import {fetchParticularRestaurant,createBooking} from '../Redux/BookTable/BookTableActions'
import {fetchRestaurant} from '../Redux/HomeFeature/HomeFeatureActions'
import {fetchRestaurantCoupons, fetchRestaurantCouponsbySlug} from '../Redux/RestaurantCoupons/RestaurantCouponActions'
import { useSelector,useDispatch } from 'react-redux'

function BookTable(){
  // store state access process start
  const restaurant_info = useSelector(state => state.Book_Table)
  const all_restaurants_info = useSelector(state =>state.All_Restaurant)
  const search_relatedinfo = useSelector(state => state.Search)
  const restaurant_coupons = useSelector(state =>state.Restaurant_Coupons)
  // store state access process end

  // action dispatch
  const dispatch = useDispatch()

  // here id get
  const {id} = useParams()

  // all component state define start
  const [key,setKey] = useState("book_a_table")

  const [bookRestaurant,setBookRestaurant] = useState([])
  const [allRestaurant,setallRestaurant] = useState([])
  const [inputValue,setInputValue] =useState({first_name : '',last_name : '',phone_no : '',email_id : ''})
  const [restaurantPreDefinedValue,setRestaurantPreDefinedValue] = useState({per_head_fees : 0,processing_fee : 0,booking_option : true,payment_option : true,menu_url : null,restaurant_phone : null,restaurant_address : null, rest_slug:null})
const [bookingProcess,setBookingProcess] = useState(false)
const [paymentValue,setPaymentValue] = useState({Payment_Complete: false, payment_token: ""})
const [coupons,setCoupons] = useState([])
const [isEmailValid, setIsEmailValid] = useState(true);
const [isPhoneValid, setIsPhoneValid] = useState(true);
const [selectedDate, setSelectedDate] = useState(null);


// all component state define end


// const define start
  const relatedRestaurant = allRestaurant ? allRestaurant.filter(
        item => item.id != id && item.booking_option != 'False'): null;
        console.log("relatedRestaurant",relatedRestaurant)
// const define end

// hooks start
  useEffect(() =>{
    dispatch(fetchParticularRestaurant(id))
    dispatch(fetchRestaurant());
    dispatch(fetchRestaurantCouponsbySlug(id));
  },[id])

  useMemo(() =>{
    setBookRestaurant(restaurant_info.restaurant.restaurant)

  },[restaurant_info.restaurant])

  useMemo(() =>{
    setallRestaurant(all_restaurants_info.restaurants.All_restaurant)

  },[all_restaurants_info.restaurants])



useMemo(() =>{
  if(bookRestaurant && bookRestaurant.length > 0){
    bookRestaurant.map(item =>{
      setRestaurantPreDefinedValue({
        per_head_fees:item.per_head_fees,
        processing_fee : item.processing_fees,
        booking_option : item.booking_option,
        payment_option : item.payment_option,
        menu_url : item.menu_url,
        restaurant_phone : item.restaurant_phone,
        restaurant_address : item.restaurant_address,
        rest_slug:item.restaurant_slug
      })
    })
  }

},[bookRestaurant])

// hooks end
useMemo(() =>{
setCoupons(restaurant_coupons.coupons)

},[restaurant_coupons.coupons])

useMemo(() => {
    if (search_relatedinfo && search_relatedinfo.bookingDate) {
        setSelectedDate(search_relatedinfo.bookingDate);
    }

}, [search_relatedinfo]);

// function description start
  const onToken = (token, addresses) => {
    setPaymentValue({ Payment_Complete: true, payment_token: token.id });
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
}

const validatePhone = (phone) => {
    const phonePattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
    return phonePattern.test(phone);
}

const handleEmailChange = (e) => {
    const email = e.target.value;
    setInputValue({...inputValue, email_id: email});

    if (!validateEmail(email)) {
        setIsEmailValid(false);
    } else {
        setIsEmailValid(true);
    }
    console.log('Emailvalid', isEmailValid);
}

const handlePhoneChange = (e) => {
    const phone = e.target.value;
    setInputValue({...inputValue, phone_no: phone});

    if (!validatePhone(phone)) {
        setIsPhoneValid(false);
    } else {
        setIsPhoneValid(true);
    }
}



  const simplebooking = () => {
    console.log('restaurantPreDefinedValue', restaurantPreDefinedValue);

    if (!validateEmail(inputValue.email_id)) {
        alert("Invalid Email format");
        return;
    }

    if (!validatePhone(inputValue.phone_no)) {
        alert("Invalid Phone Number format");
        return;
    }


    setBookingProcess(true);
    let total_booking_amount = 0;
    const peoplenumber = search_relatedinfo.people != null ? search_relatedinfo.people : 2;
    let payment_token = "";
    if (restaurantPreDefinedValue.payment_option == "false") {
      total_booking_amount = 0;
      payment_token = 10000000;
    } else {
      total_booking_amount = ((restaurantPreDefinedValue.per_head_fees * parseInt(peoplenumber)) +
      restaurantPreDefinedValue.processing_fee);
      payment_token = paymentValue.payment_token;
    }

    // Check if the individual values are not null or undefined
if (restaurantPreDefinedValue && restaurantPreDefinedValue.per_head_fees != null && inputValue && inputValue.people != null) {
    let total = parseInt((restaurantPreDefinedValue.per_head_fees * inputValue.people) +
      restaurantPreDefinedValue.processing_fee);
    console.log(total); // Check the output
    console.log("inputValue.people", inputValue.people); // Check the output
} else {
    console.log('One of the input values is null or undefined');
    console.log("inputValue.people", inputValue.people);
}

    const booking_data = {
      first_name : inputValue.first_name,
      last_name : inputValue.last_name,
      phone_number : inputValue.phone_no,
      email_id : inputValue.email_id,
      people : search_relatedinfo.people,
      booking_date:selectedDate,
      booking_time:search_relatedinfo.bookingTime,
      location : search_relatedinfo.current_address,
      //location : "ambala",
      booking_amount : total_booking_amount,
      payment_token : payment_token,
      booking_status :"pending",
      rest_id : restaurantPreDefinedValue.rest_slug,
      coupon_id : "1"
    }
    dispatch(createBooking(booking_data));
  }

  if (paymentValue.Payment_Complete === true) {
      console.log("submitt");
      simplebooking()
      setPaymentValue({...paymentValue, Payment_Complete: false });
    }

    // function description end

  return(
    <>
      <Header />
      {bookRestaurant && bookRestaurant.length > 0 ? bookRestaurant.map(restaurant =>(
        <div className="book-table-main booktablepage">
          {}
          <div className="bt-gellary">
            <div className="container">
              <div className="bt-gellary-wrapper">
                <div className="row no-gutters rest-gallery">
                  <div className="col-12">
                    <div className="btg-left">
                      <div className="row no-gutters">
                        {restaurant.banner_image != null ? (
                          <div className="col-lg-12 col-md-12 rest-banner">
                            <div className="btg-one">
                              <img
                                src={restaurant.banner_image}
                                alt="images not found"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="col-lg-12 col-md-12 rest-banner">
                            <div className="btg-one">
                              <img
                                src="/img/bt1.png"
                                alt="images not found"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="b-the">
            <div className="container">
              <div className="b-the-wrapper">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="b-the-left">
                      <h4>{restaurant.restaurant_name}</h4>
                      <p className="res-des">{restaurant.restaurant_description}</p>
                      <p>


                      {coupons?.length > 0 ?
                        coupons.map((coupon, index) => {
                          const currentRestCoupon = coupon.All_coupon.find(fil_coupon => fil_coupon.restaurant_id === restaurant.id);

                          return currentRestCoupon ? (
                                  <div className="col-md-6 offerlist">
                                    <p>Offer Available - {currentRestCoupon.coupon_name}</p>
                                  </div>
                          ) : null;
                        })
                      : null}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="b-the-right text-right">
                      <h4>4.5</h4>
                      <p>20 reviews</p>
                      {/*<a href="#">Book a Table</a>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="b-menu">
              <div className="container">
                <div className="b-menu-wrapper">
                  <div className="row">
                    <div className="col-lg-8">
                    <div className="b-menu-left r-menu-left">
                    <div className="errormsg">{restaurant_info && restaurant_info.success_booking_data && restaurant_info.success_booking_data.error && restaurant_info.success_booking_data.error.booking_date ? restaurant_info.success_booking_data.error.booking_date : null} <br/>{restaurant_info && restaurant_info.success_booking_data && restaurant_info.success_booking_data.error && restaurant_info.success_booking_data.error.booking_time ? restaurant_info.success_booking_data.error.booking_time : null}</div>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={key => setKey(key)}
                    >
                      <Tab eventKey="overview" title="Overview">
                        <div className="rview">
                          <div className="row no-gutters">
                            <div className="col-lg-3 col-md-4">
                              <div className="rview-one-wrapper">
                                <div className="rview-one">
                                  <h6>Phone Number</h6>
                                  <p>+1 621-333-1111</p>
                                  <p>Table Booking</p>
                                  <p>recommended</p>
                                </div>
                                <div className="rview-one review-two">
                                  <h6>Cuisines</h6>
                                  <p>Continental, Pizzaf,</p>
                                  <p>Burger, American</p>
                                </div>
                                <div className="rview-one review-two review-three">
                                  <h6>Average Cost</h6>
                                  <p>$200 for two People</p>
                                  <p>
                                    Exclusive of applicable taxes and
                                    charges, if any
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-5 col-md-4">
                              <div className="r-maps-wrapper">
                                <div className="r-maps">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.0916927704875!2d-72.29734968521689!3d41.0013662278001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e8bd252d45d0c9%3A0xd9264a0d6dd0447f!2s39+Main+St%2C+Sag+Harbor%2C+NY+11963%2C+USA!5e0!3m2!1sen!2sbd!4v1561227883290!5m2!1sen!2sbd"
                                    frameBorder={0}
                                    style={{
                                      border: 0
                                    }}
                                    allowFullScreen
                                  />
                                  <h6>Address</h6>
                                  <p>39 Main St, Sag Harbor,</p>
                                  <p>NY 11963-3012</p>
                                </div>
                                <div className="r-maps-para">
                                  <h6>Opening hours</h6>
                                  <p>Monday - Sunday</p>
                                  <p>12noon -2am</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                              <div className="r-more">
                                <h6>More Info</h6>
                                <ul>
                                  <li>
                                    {" "}
                                    <span>
                                      <i className="fas fa-check" />
                                    </span>
                                    Wheelchair Accessible
                                  </li>
                                  <li>
                                    {" "}
                                    <span>
                                      <i className="fas fa-check" />
                                    </span>
                                    Wine and Beer
                                  </li>
                                  <li>
                                    {" "}
                                    <span>
                                      <i className="fas fa-check" />
                                    </span>
                                    Valet Parking Available
                                  </li>
                                  <li>
                                    {" "}
                                    <span>
                                      <i className="fas fa-check" />
                                    </span>
                                    Indoor Seating
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey="book_a_table" title="Book a Table">
                      {!isEmailValid ? <p className="email_error error">Invalid Email format</p> : null}
                      {!isPhoneValid ? <p className="email_error error">Invalid Phone Number format</p> : null}
                      {inputValue.email_id != "" && inputValue.phone_no != "" & isEmailValid && isPhoneValid && selectedDate === null ? <p className="email_error error">Please select a booking Date</p> : null}
                        {!restaurant_info.booking_loading && !restaurant_info.success_booking_data.Bookings ? (
                          <div className="batable">
                            <SearchForm />
                            <form id="bookingform">
                              <div className="bguest">
                                <p>Enter guest details</p>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="b-guest-one">
                                      <label>First Name</label>
                                      <input
                                        type="text"
                                        required
                                        value={inputValue.first_name}
                                        onChange={e =>
                                          setInputValue({...inputValue,first_name : e.target.value})
                                        }
                                        placeholder="Enter First Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="b-guest-one">
                                      <label>Last Name</label>
                                      <input
                                        type="text"
                                        required
                                        value={inputValue.last_name}
                                        onChange={e =>
                                          setInputValue({...inputValue,last_name : e.target.value})
                                        }
                                        placeholder="Enter Last Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="b-guest-one">
                                      <label>Email</label>
                                      <input
                                        type="email"
                                        required
                                        value={inputValue.email_id}
                                        onChange={handleEmailChange}
                                        placeholder="Enter Email"
                                    />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                    <div className="b-guest-one b-guest-number">
                                      <label>Phone Number</label>
                                      <input
                                        id="b-code"
                                        type="text"
                                        name="text"
                                        placeholder={+1}
                                      />
                                      <input
                                        id="b-number"
                                        type="phone"
                                        required
                                        value={inputValue.phone_no}
                                        onChange={handlePhoneChange}
                                        placeholder={"Enter Phone No"}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            {restaurantPreDefinedValue.booking_option != "false" &&
                          restaurantPreDefinedValue.payment_option != "false" ? (
                              <div className="b-guest-link">
                                {inputValue.first_name != "" &&
                                inputValue.last_name != "" &&
                                inputValue.phone_no != "" &&
                                inputValue.email_id != "" && isEmailValid && isPhoneValid && selectedDate != null  ? (
                                  <StripeCheckout
                                    amount={
                                      (restaurantPreDefinedValue.per_head_fees *
                                        inputValue.people +
                                        parseInt(
                                          restaurantPreDefinedValue.processing_fee
                                        )) *
                                      100
                                    }
                                    description="Restaurant on Demand"
                                    locale="auto"
                                    className="checkout"
                                    email="support@dinereserve.com"
                                    name="DineReserve"
                                    stripeKey="pk_test_SuRxpCfO2ddC7xFgx1UEEMNJ"
                                    token={(token, addresses) => onToken(token, addresses)}
                                    label="Book Now"
                                    allowRememberMe={false}
                                  />
                                ) : isEmailValid && isPhoneValid ?
                                <button
                                  type="submit"
                                  form="bookingform"
                                  className="StripeCheckout"
                                  value="submit"
                                >
                                  <span>Book Now</span>
                                </button>
                                :
                                <button
                                  type="submit"
                                  form="bookingform"
                                  className="StripeCheckout"
                                  value="submit"
                                  disabled
                                >
                                  <span>Book Now</span>
                                </button>

                                }
                                <div className="cost-per-person">
                                  <p>All reservations require a ${restaurantPreDefinedValue.per_head_fees} per person reservation fee which will be applied to your final bill.</p>
                                </div>
                              </div>
                            ) : null}
                            {restaurantPreDefinedValue.booking_option != "false" &&
                            restaurantPreDefinedValue.payment_option == "false" ? (
                              inputValue.first_name != "" &&
                              inputValue.last_name != "" &&
                              inputValue.phone_no != "" &&
                              inputValue.email_id != "" ? (
                                <div className="b-guest-link">
                                  <button
                                    type="submit"
                                    form="bookingform"
                                    className="StripeCheckout"
                                    value="submit"
                                    onClick={() =>simplebooking()}
                                  >
                                    <span>Book Now</span>
                                  </button>
                                </div>
                              ) : (
                                <div className="b-guest-link">
                                  <button
                                    type="submit"
                                    form="bookingform"
                                    className="StripeCheckout"
                                    value="submit"
                                  >
                                    <span>Book Now</span>
                                  </button>
                                </div>
                              )
                            ) : null}
                            {restaurantPreDefinedValue.booking_option == "False" ? (
                              <div className="b-guest-link">
                                <button
                                  form="bookingform"
                                  className="StripeCheckout"
                                  disabled
                                >
                                  <span>Booking Closed</span>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : restaurant_info.bookingcompleted && restaurant_info.success_booking_data.Bookings ? (
                          <div className="Booking-confrmation">
                            <h2>Thank You!</h2>
                            <h2>Booking Successfull!</h2>
                            <h3>
                              Please check your email for Booking status.
                            </h3>
                          </div>
                        ) : (
                          <div className="Booking-loader">
                            <img src="/img/loader.gif"></img>
                          </div>
                        )}
                      </Tab>
                      <Tab eventKey="menu" title="Menu">
                        <div>
                          <a
                            href={restaurantPreDefinedValue.menu_url}
                            className="btn menu-button"
                            target="_blank"
                          >
                            View Menu
                          </a>
                        </div>
                      </Tab>
                      <Tab eventKey="reviews" title="Contact Info">
                      <span className="restaurant-phone"><h6>Phone Number:</h6><p>{restaurantPreDefinedValue.restaurant_phone}</p></span>
                        <span className="restaurant-address"><h6>Address:</h6><p>{restaurantPreDefinedValue.restaurant_address}</p></span>
                      </Tab>
                    </Tabs>
                  </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="b-page">

                        {relatedRestaurant && relatedRestaurant.length > 0 ? relatedRestaurant.slice(0,2).map((restaurant,index) =>(
                          <div className="b-page-one">
                            <div className="b-page-one-wrapper">
                              <div className="b-page-one-img">
                                <img
                                  src={restaurant.logo_image}
                                  alt="images not found"
                                />
                              </div>
                              <div className="b-page-one-para">
                                <h4>{restaurant.restaurant_name}</h4>
                                <p>{restaurant.restaurant_description}
                                </p>
                              </div>
                              <div className="b-page-one-rating">
                                <h5>4.5</h5>
                                <p>20 reviews</p>
                              </div>
                            </div>
                            <div className="b-page-link text-center">
                              <a href={`/booktable/${restaurant.restaurant_slug}`}>
                                Book Your Table{" "}
                                <span>
                                  <i className="fas fa-angle-right" />
                                </span>
                              </a>
                            </div>
                          </div>
                        )

                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

      )

        ) : (
          <div className="Booking-loader">
            <img src="/img/loader.gif"></img>
          </div>
        )}
      <Footer />
      <FooterBottom />
    </>
  )
}

export default BookTable
