import {
  FETCH_PARTICULAR_COUPON_REQUEST,
  FETCH_PARTICULAR_COUPON_SUCCESS,
  FETCH_PARTICULAR_COUPON_FALIURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_ERROR
} from './UpdateCouponConstants'

const intialstate = {
  particular_coupon_loading : false,
  particular_coupon : [],
  particular_coupon_error : '',
  particular_coupon_success : false,
  update_coupon_loading : false,
  update_coupon : [],
  update_coupon_error : '',
  update_coupon_success : false,
}

const UpdateCouponReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_PARTICULAR_COUPON_REQUEST : return{
      ...state,
      particular_coupon_loading:true
    }
    case FETCH_PARTICULAR_COUPON_SUCCESS : return{
      ...state,
      particular_coupon_loading:false,
      particular_coupon_success:true,
      particular_coupon:action.payload
    }
    case FETCH_PARTICULAR_COUPON_FALIURE : return{
      ...state,
      particular_coupon_error:action.payload
    }
    case UPDATE_COUPON_REQUEST : return{
      ...state,
      update_coupon_loading:true
    }
    case UPDATE_COUPON_SUCCESS : return{
      ...state,
      update_coupon_loading:false,
      update_coupon_success:true,
      update_coupon:action.payload
    }
    case UPDATE_COUPON_ERROR : return{
      ...state,
      update_coupon_error:action.payload
    }
    default: return state
  }

}

export default UpdateCouponReducers
