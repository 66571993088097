import {
  ADD_COUPON_REQUEST,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_ERROR
} from './AddCouponConstants'
import {config} from '../../config'

export const addCouponRequest = (data,res_id) =>{
  return{
    type : ADD_COUPON_REQUEST,
    payload : data,
    rest_id : res_id
  }
}

export const addCouponSuccess = (add_coupon_success) =>{
  return{
    type : ADD_COUPON_SUCCESS,
    payload : add_coupon_success
  }
}

export const addCouponFaliure = (error) =>{
  return{
    type : ADD_COUPON_ERROR,
    payload : error
  }
}

export const addCoupon = (data,res_id) =>{
    return(dispatch) => {
      dispatch(addCouponRequest(data,res_id))
      const url =`${config.api_root}/couponadd/${res_id}`;
      const request_option = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(addCoupon_res =>{
      const add_coupon_success = addCoupon_res
      dispatch(addCouponSuccess(add_coupon_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(addCouponFaliure(errorMsg))
    })
    }
}
