import React ,{useState,useEffect,useMemo}from 'react'
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {fetchRestaurant} from '../Redux/HomeFeature/HomeFeatureActions'
import { useSelector,useDispatch } from 'react-redux'
import {fetchRestaurantCoupons} from '../Redux/RestaurantCoupons/RestaurantCouponActions'

function HomeFeature2(){
  const feature_restaurants = useSelector(state =>state.All_Restaurant)
  const restaurant_coupons = useSelector(state =>state.Restaurant_Coupons)
  console.log("this.props.feature_restaurants",feature_restaurants)
  const [featureRestaurant,setFeatureRestaurant] = useState([])
    const [coupons,setCoupons] = useState([])

  console.log("featureRestaurant",featureRestaurant)

  const dispatch = useDispatch();
  useEffect(() =>{
    dispatch(fetchRestaurant());
  },[dispatch])
  useMemo(() =>{
    setFeatureRestaurant(feature_restaurants.restaurants.All_restaurant)

  },[feature_restaurants.restaurants])

  useMemo(() =>{
    if(featureRestaurant && featureRestaurant.length > 0){
      featureRestaurant.filter(item => item.booking_option != 'False').map((data, i) =>{
        dispatch(fetchRestaurantCoupons(data.id));
      } )
    }

  },[featureRestaurant])

  useMemo(() =>{
  setCoupons(restaurant_coupons.coupons)

  },[restaurant_coupons.coupons])

  return(
    <div className="book-table-main">
     <div className="srating">
       <div className="container">
         <div className="srating-wrapper">
           <div className="row">
           {featureRestaurant
             ? featureRestaurant.filter(item => item.booking_option != 'False' && item.admin_approval != 0).map((data, i) => (
             <div className="col-lg-12 col-xl-6" key={i}>
               <div className="b-page-one resto-order">
                 <div className="b-page-one-wrapper restaurent-box">
                   <div className="b-page-one-img retaurent-img">
                     <img className="img-fluid" src={data.logo_image ? data.logo_image : '/img/banneimg.jpeg'} alt="images not found" />
                   </div>
                   <div className="retaurent-txt">
                       <div className="rating-rest">
                         <i className="fas fa-star" />{" "}
                         5
                       </div>
                       <div className="retaurent-name">
                           <a href={`/booktable/${data.restaurant_slug}`}>
                             <h3>
                               {data.restaurant_name}
                             </h3>
                           </a>

                         <p>{data.restaurant_description}</p>
                       </div>
                       <div className="time-close">
                         <span className="rest-time">
                         {data.coupons && data.coupons.length > 0 ? data.coupons[0].coupon_name : null}
                         
                         </span>
                         <span className="rest-close">

                           <a href={`/booktable/${data.restaurant_slug}`}>
                             Book Table
                             </a>
                         </span>
                       </div>
                     </div>
</div>
               </div>

             </div>
             )): (<div className="rest-loader">
             <img src="/img/loader.gif"/></div>)}
             </div>
             </div>
           </div>
         </div>
     <div className="spagi">
       <div className="container">
         <div className="spagi-wrapper text-right">
           <div className="spagi-right">


           </div>
         </div>
       </div>
     </div>
   </div>
  )
}

export default HomeFeature2
