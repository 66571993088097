import React ,{useEffect,useState,useMemo} from 'react'
import AdminHeader from './AdminHeader'
import AdminFooter from './AdminFooter'
import {
  Table,
  Button,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import {fetchRestaurant} from '../../Redux/HomeFeature/HomeFeatureActions'
import {fetchParticularRestaurant} from '../../Redux/BookTable/BookTableActions'
import {updateRestaurant, approveRestaurant, deleteRestaurant} from '../../Redux/Admin/RestaurantList/RestaurantListActions'
import { useSelector,useDispatch } from 'react-redux'

function Restaurantlist(){
  const feature_restaurants = useSelector(state =>state.All_Restaurant)
  const restaurant_info = useSelector(state => state.Book_Table)
  const restaurant_coupons = useSelector(state =>state.Restaurant_Coupons)
  const restaurant_update = useSelector(state =>state.RestaurantList)
  const dispatch = useDispatch();
  const [featureRestaurant,setFeatureRestaurant] = useState([])
  const [selectedRest,setSelectedRest] = useState(null)
  const [resId,setResId] = useState(null)
  const [particularRestaurant,setParticularRestaurant] = useState([])
  const [coupons,setCoupons] = useState([])
  const [addresponse,setAddresponse] = useState(null)
  const [inputValues,setInputvalues] = useState({restaurant_id:'',
                                              restaurant_email_id:'',
                                              restaurant_name:'',
                                              restaurant_phone:'',
                                              per_head_fees:'',
                                              processing_fees:'',
                                              payment_option:'',
                                              booking_option:'',
                                              restaurant_coupon_status:'',
                                              restaurant_description:'',
                                              restaurant_rating:'',
                                              restaurant_review:'',
                                              restaurant_cuisines:'',
                                              restaurant_address:'',
                                              menu_url:'',
                                              logo_image:'',
                                              banner_image:'',
                                              restaurant_opening_hours:'',
                                              restaurant_closing_hours:'',
                                              restaurant_extra_information:'',
                                              restaurant_slug:''
                                            })

  useEffect(() =>{
    dispatch(fetchRestaurant());
  },[dispatch])

  useMemo(() =>{
    setFeatureRestaurant(feature_restaurants.restaurants.All_restaurant)

  },[feature_restaurants.restaurants])


  useMemo(() =>{
    setParticularRestaurant(restaurant_info.restaurant.restaurant)

  },[restaurant_info.restaurant])

  useMemo(() =>{
    if(particularRestaurant && particularRestaurant.length > 0){
      particularRestaurant.map(item =>{
        setInputvalues({
          restaurant_id:item.id,
          restaurant_email_id : item.restaurant_email,
          restaurant_name : item.restaurant_name,
          restaurant_phone : item.restaurant_phone,
          per_head_fees : item.per_head_fees,
          processing_fees : item.processing_fees,
          payment_option : item.payment_option,
          booking_option : item.booking_option,
          restaurant_coupon_status : item.restaurant_coupon_status,
          restaurant_description : item.restaurant_description,
          restaurant_rating : item.restaurant_rating,
          restaurant_review : item.restaurant_review,
          restaurant_cuisines : item.restaurant_cuisines,
          restaurant_address : item.restaurant_address,
          menu_url : item.menu_url,
          logo_image:item.logo_image,
          banner_image : item.banner_image,
          restaurant_opening_hours:item.restaurant_opening_hours,
          restaurant_closing_hours:item.restaurant_closing_hours,
          restaurant_extra_information:item.restaurant_extra_information,
          restaurant_slug:item.restaurant_slug,
          admin_approval:item.admin_approval
        })

      })
    }

  },[particularRestaurant])

  useEffect(() =>{
    if(resId && resId != null){
      dispatch(fetchParticularRestaurant(resId));
    }
  },[resId])

    const orgSelectFieldChange = (e) =>{
      setResId(e.target.value)

    }

    const bannerFieldChange = (event) => {
    const input = event.target;
    if (input.files.length > 0) {
      const reader = new FileReader();
      reader.onload = info => {
        setInputvalues({...inputValues,banner_image:reader.result})
        console.log("reader.result-------------",reader.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
    console.log("banner_image-------------",inputValues.banner_image);
  };

  const logoFieldChange = (event) => {
    const input = event.target;
    if (input.files.length > 0) {
      const reader = new FileReader();
      reader.onload = info => {
        setInputvalues({...inputValues,logo_image:reader.result})
      };
      reader.readAsDataURL(input.files[0]);
    }
    console.log("banner_image-------------",inputValues.banner_image);
  };

  const simplebooking = () =>{
    const data = inputValues;
    dispatch(updateRestaurant(data))
  }

  const approveRest = (restid) =>{
    const data = restid;
    dispatch(approveRestaurant(restid))
  }
  const deleteRest = (restid) =>{
    const data = restid;
    dispatch(deleteRestaurant(restid))

  }



  useMemo(() =>{
    if(restaurant_update && restaurant_update.update_restaurant_success === true){
      setAddresponse(true)
      const timer = setTimeout(() => {
          window.location.reload();
      }, 1000);
    }
  },[restaurant_update])

  useMemo(() =>{
    if(restaurant_update && restaurant_update.loading === true){
      setAddresponse(100)
    }
  },[restaurant_update])

  const all_Restaurant = featureRestaurant && featureRestaurant.length > 0 ? featureRestaurant.filter((items) => items.user_id === sessionStorage.getItem("user_id")).map(
      (item, i) => (
        <option key={i} value={item.restaurant_slug} name={item.restaurant_name}>
          {item.restaurant_name}
        </option>
      )
    ):null;

    const all_Restaurant2 = featureRestaurant && featureRestaurant.length > 0 ? featureRestaurant.map(
        (item, i) => (
          <option key={i} value={item.restaurant_slug} name={item.restaurant_name}>
            {item.restaurant_name}
          </option>
        )
      ):null;

    const responseArea = addresponse === 100 ? (
      <div className="ResturantInfo-loader">
        <img src="/img/loadernew.gif"></img>
      </div>
    ) : (
      <div className="RestaurantInfo-confrmation">
        <h2 className="RestaurantInfo-confrmation-heading">Thank You!</h2>
        <h2>Restaurant Updated Successfully!</h2>

      </div>

    );
  return(
    <>
    <div className="admin_body">
    <AdminHeader />
    {addresponse === null ?
      (<div className="row">
        <div className="col-md-3">{""}</div>
        <div className="col-md-6 restaurant-info">
        <FormGroup>
          <FormLabel>Select Restaurant </FormLabel>
          <FormControl
            required
            type="text"
            onChange={e => orgSelectFieldChange(e)}
            as="select"
          >
            <option>{selectedRest !== null ? selectedRest : 'Select Booking Option'}</option>
            {sessionStorage.getItem("user_id") == '41' ? all_Restaurant2 : all_Restaurant}
          </FormControl>
        </FormGroup>
        {restaurant_info.restaurant_success === true ?
          (<div class={restaurant_update && restaurant_update.approve_restaurant_info && restaurant_update.approve_restaurant_info.message ? 'approvedone' : ''}><h1>Restaurant Information</h1>
          <hr></hr>
          {sessionStorage.getItem("user_id") == '41' && inputValues.admin_approval === 0 ? (<div className="approve"><button onClick={(e) =>approveRest(inputValues.restaurant_slug)}>Approve</button></div>):null}
          {restaurant_update && restaurant_update.approve_restaurant_info && restaurant_update.approve_restaurant_info.message ? (<span className="approvedsuccess">Restaurant Approved Successfully</span>) : null}
          <div className="approve deleterest"><button onClick={(e) =>deleteRest(inputValues.restaurant_slug)}>Delete Retaurant</button></div>
          <form action="" onSubmit={(e) =>simplebooking(e)}>
            <Table>
              <tbody>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant ID</FormLabel>
                    <FormControl
                      required
                      type="text"
                      disabled
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_slug:e.target.value})
                      }
                      value={inputValues.restaurant_slug}
                    />
                  </FormGroup>
                </td>

                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Name</FormLabel>
                      <FormControl
                        required
                        type="text"
                        onChange={e =>
                          setInputvalues({...inputValues,restaurant_name:e.target.value})
                        }
                        value={inputValues.restaurant_name}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Email ID</FormLabel>
                    <FormControl
                      required
                      type="email"
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_email_id:e.target.value})
                      }
                      value={inputValues.restaurant_email_id}
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Phone</FormLabel>
                      <FormControl
                        required
                        type="phone"
                        onChange={e =>
                          setInputvalues({...inputValues,restaurant_phone:e.target.value})
                        }
                        value={inputValues.restaurant_phone}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Booking Options</FormLabel>
                    <FormControl
                      required
                      type="text"
                      onChange={e =>
                        setInputvalues({...inputValues,booking_option:e.target.value})
                      }
                      as="select"
                    >
                      <option value="">Select Booking Option</option>
                      <option
                      selected = {inputValues.booking_option === "true" ? true : null}
                      value={true}>True</option>
                      <option value={false}
                      selected = {inputValues.booking_option === "false" ? true : null}
                      >False</option>
                    </FormControl>
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Processing Fee</FormLabel>
                      <FormControl
                        required
                        type="number"
                        onChange={e =>
                          setInputvalues({...inputValues,processing_fees:e.target.value})
                        }
                        value={inputValues.processing_fees}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Per Head Fees</FormLabel>
                    <FormControl
                      required
                      type="number"
                      onChange={e =>
                        setInputvalues({...inputValues,per_head_fees:e.target.value})
                      }
                      value={inputValues.per_head_fees}
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Payment Options</FormLabel>
                      <FormControl
                        required
                        type="text"
                        onChange={e =>
                          setInputvalues({...inputValues,payment_option:e.target.value})
                        }
                        as="select"
                      >
                        <option value="">Select Payment Option</option>
                        <option
                        selected = {inputValues.payment_option === "true" ? true : null}
                        value={true}>True</option>
                        <option value={false}
                        selected = {inputValues.payment_option === "false" ? true : null}
                        >False</option>
                      </FormControl>
                    </FormGroup>
                  </td>

                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Address</FormLabel>
                    <FormControl
                      type="textarea"
                      as="textarea"
                      rows="3"
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_address:e.target.value})
                      }
                      value={inputValues.restaurant_address}
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>restaurant_cuisines</FormLabel>
                      <FormControl
                        as="textarea"
                        rows="3"
                        type="textarea"
                        onChange={e =>
                          setInputvalues({...inputValues,restaurant_cuisines:e.target.value})
                        }
                        value={inputValues.restaurant_cuisines}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Description</FormLabel>
                    <FormControl
                      as="textarea"
                      rows="3"
                      type="textarea"
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_description:e.target.value})
                      }
                      value={inputValues.restaurant_description}
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Review</FormLabel>
                      <FormControl
                        type="textarea"
                        as="textarea"
                        rows="3"
                        onChange={e =>
                          setInputvalues({...inputValues,restaurant_review:e.target.value})
                        }
                        value={inputValues.restaurant_review}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Coupon Status</FormLabel>
                    <FormControl
                      type="text"
                      required
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_coupon_status:e.target.value})
                      }
                      as="select"
                    >
                      <option value="">Select Coupon Status</option>
                      <option
                      selected = {inputValues.restaurant_coupon_status === "true" ? true : null}
                      value={true}>True</option>
                      <option value={false}
                      selected = {inputValues.restaurant_coupon_status === "false" ? true : null}
                      >False</option>
                    </FormControl>
                  </FormGroup>
                </td>

                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Rating</FormLabel>
                      <FormControl
                        type="text"
                        onChange={e =>
                          setInputvalues({...inputValues,restaurant_rating:e.target.value})
                        }
                        value={inputValues.restaurant_rating}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td >
                <FormGroup>
                  <FormLabel>Menu URL</FormLabel>
                  <FormControl
                    type="text"
                    onChange={e =>
                      setInputvalues({...inputValues,menu_url:e.target.value})
                    }
                    value={inputValues.menu_url}
                  />
                </FormGroup>
                </td>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Slug</FormLabel>
                    <FormControl
                      type="text"
                      disabled
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_slug:e.target.value})
                      }
                      value={inputValues.restaurant_slug}
                    />
                  </FormGroup>
                </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Restaurant Opening Hours</FormLabel>
                    <FormControl
                      type="textarea"
                      as="textarea"
                      rows="3"
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_opening_hours:e.target.value})
                      }
                      value={inputValues.restaurant_opening_hours}
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Restaurant Closing Hours</FormLabel>
                      <FormControl
                        type="textarea"
                        as="textarea"
                        rows="3"
                        onChange={e =>
                          setInputvalues({...inputValues,restaurant_closing_hours:e.target.value})
                        }
                        value={inputValues.restaurant_closing_hours}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td colSpan="12">
                  <FormGroup>
                    <FormLabel>Restaurant Extra Information</FormLabel>
                    <FormControl
                      type="textarea"
                      as="textarea"
                      rows="3"
                      onChange={e =>
                        setInputvalues({...inputValues,restaurant_extra_information:e.target.value})
                      }
                      value={inputValues.restaurant_extra_information}
                    />
                  </FormGroup>
                </td>

                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Banner Image</FormLabel>
                    <FormControl
                      type="file"
                      onChange={event =>
                        bannerFieldChange(event)
                      }
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Logo Image</FormLabel>
                      <FormControl
                        type="file"
                        onChange={event =>
                          logoFieldChange(event)
                        }
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td colSpan="12">
                    <Button type="submit" className="org-btn-create">
                      Update Options
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </form></div>)
         : <div className="emptyData"></div>}
        </div>
        <div className="col-md-3">{""}</div>
      </div>):responseArea}

    </div>
    <AdminFooter />
    </>
  )
}

export default Restaurantlist
