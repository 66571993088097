import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR
} from './AdminLoginConstants'

const intialstate = {
  loading : false,
  login : [],
  error : '',
  success : false
}

const AdminLoginReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ADMIN_LOGIN_REQUEST : return{
      ...state,
      loading:true
    }
    case ADMIN_LOGIN_SUCCESS : return{
      ...state,
      loading:false,
      success:true,
      login:action.payload
    }
    case ADMIN_LOGIN_ERROR : return{
      ...state,
      error:action.payload
    }
    default: return state
  }

}

export default AdminLoginReducers
