import {
  FETCH_RESTAURANT_COUPONS_REQUEST,
  FETCH_RESTAURANT_COUPONS_SUCCESS,
  FETCH_RESTAURANT_COUPONS_ERROR
} from './RestaurantCouponConstants'
import {config} from '../config'

export const fetchRestaurantCouponRequest = (rest_id) =>{
  return{
    type : FETCH_RESTAURANT_COUPONS_REQUEST,
    payload : rest_id
  }
}

export const fetchRestaurantCouponSuccess = (coupons) =>{
  return{
    type : FETCH_RESTAURANT_COUPONS_SUCCESS,
    payload : coupons
  }
}

export const fetchRestaurantCouponFailure = (error) =>{
  return{
    type : FETCH_RESTAURANT_COUPONS_ERROR,
    payload : error
  }
}

export const fetchRestaurantCoupons = (rest_id) =>{
    return(dispatch) => {
      dispatch(fetchRestaurantCouponRequest(rest_id))
      const url =`${config.api_root}/all-coupon/${rest_id}`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(coupon_info =>{
      const coupons = coupon_info
      dispatch(fetchRestaurantCouponSuccess(coupons))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchRestaurantCouponFailure(errorMsg))
    })
    }
}

export const fetchRestaurantCouponsbySlug = (rest_id) =>{
    return(dispatch) => {
      dispatch(fetchRestaurantCouponRequest(rest_id))
      const url =`${config.api_root}/coupon-by-slug/${rest_id}`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(coupon_info =>{
      const coupons = coupon_info
      dispatch(fetchRestaurantCouponSuccess(coupons))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchRestaurantCouponFailure(errorMsg))
    })
    }
}
