import {
  ADMIN_SIGNUP_REQUEST,
  ADMIN_SIGNUP_SUCCESS,
  ADMIN_SIGNUP_ERROR
} from './AdminSignupConstants'

const intialstate = {
  loading : false,
  signup : [],
  error : '',
  success : false
}

const AdminSignupReducers = (state = intialstate,action) =>{
  switch(action.type){
    case ADMIN_SIGNUP_REQUEST : return{
      ...state,
      loading:true
    }
    case ADMIN_SIGNUP_SUCCESS : return{
      ...state,
      loading:false,
      success:true,
      signup:action.payload
    }
    case ADMIN_SIGNUP_ERROR : return{
      ...state,
      error:action.payload
    }
    default: return state
  }

}

export default AdminSignupReducers
