import React, { useEffect, useState, useMemo } from 'react';
import AdminFooter from './AdminFooter';
import AdminHeader from './AdminHeader';
import { adminSignup } from '../../Redux/Admin/AdminSignup/AdminSignupActions';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Redirect } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';

function AdminSignup() {
    const admin_signup = useSelector(state => state.AdminSignup);
    const [inputValue, setInputValue] = useState({
        restaurant_name: '',
        email: '',
        password: '',
        phone_number: ''
    });
    const [error, setError] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [paymentToken, setPaymentToken] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isValidating, setIsValidating] = useState(false);
    const [infoCheckCompleted, setInfoCheckCompleted] = useState(false);

    const dispatch = useDispatch();

    useMemo(() => {
        if (admin_signup.signup.error) {
            setError(admin_signup.signup.error);
        }
    }, [admin_signup.signup.error]);

    const onToken = (token, addresses) => {
        // Save the token and set payment as complete
        setPaymentToken(token.id);
        setPaymentComplete(true);
        const signup_info = {
            restaurant_name: inputValue.restaurant_name,
            email: inputValue.email,
            password: inputValue.password,
            phone_number: inputValue.phone_number,
            payment_token: paymentToken
        };

        dispatch(adminSignup(signup_info));
    };

    const validateEmail = email => {
       const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
       return emailPattern.test(email);
   }

   const validatePhone = phone => {
       const phonePattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
       return phonePattern.test(phone);
   }

    const handleSubmit = e => {
        e.preventDefault();
        setIsValidating(true);
        if (!validateEmail(inputValue.email)) {
            setIsEmailValid(false);
        } else {
            setIsEmailValid(true);
        }
        if (!validatePhone(inputValue.phone_number)) {
            setIsPhoneValid(false);
        } else {
            setIsPhoneValid(true);
        }
        setIsValidating(false);
        // if (!paymentComplete) {
        //     alert("Please complete the payment first.");
        //     return;
        // }
        if(validateEmail(inputValue.email) && validatePhone(inputValue.phone_number) && inputValue.restaurant_name != '' && inputValue.password != '') {
          setInfoCheckCompleted(true)
        }

    };

    if (admin_signup.signup.status === true) {
        window.location.href = '/admin/admin_login';
    }

    const renderError = (field) => {
        return error && error[field] ? (
            <h6 className="signup-error">
                *{JSON.stringify(error[field]).replace(/[\[\]"]+/g, "")}
            </h6>
        ) : null;
    };

    return (
      <div className='container-fluid'>
         <AdminHeader />
         <div className='row adminLogin'>
             <div className='col-md-4'></div>
             <div className='col-md-4'>
                 <div className='signup-page' />
                 <div className="Login">
                 {!isEmailValid ? <p className="email_error error">Invalid Email format</p> : null}
                 {!isPhoneValid ? <p className="email_error error">Invalid Phone Number format</p> : null}
                     <Form onSubmit={(e) => handleSubmit(e)}>
                        <Form.Group controlId="restaurant_name">
                            <Form.Label>Restaurant Name</Form.Label>
                            <Form.Control
                                autoFocus
                                type="text"
                                required
                                disabled = {infoCheckCompleted ? true : false}
                                value={inputValue.restaurant_name}
                                onChange={e => setInputValue({ ...inputValue, restaurant_name: e.target.value })}
                            />
                            {renderError('restaurant_name')}
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                value={inputValue.email}
                                disabled = {infoCheckCompleted ? true : false}
                                onChange={e => setInputValue({ ...inputValue, email: e.target.value })}
                            />
                            {renderError('email')}
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                value={inputValue.password}
                                disabled = {infoCheckCompleted ? true : false}
                                onChange={e => setInputValue({ ...inputValue, password: e.target.value })}
                            />
                            {renderError('password')}
                        </Form.Group>

                        <Form.Group controlId="phone_number">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={inputValue.phone_number}
                                disabled = {infoCheckCompleted ? true : false}
                                onChange={e => setInputValue({ ...inputValue, phone_number: e.target.value })}
                            />
                            {renderError('phone_number')}
                        </Form.Group>

                        {infoCheckCompleted ? <div className="pymntbtn">
                            <StripeCheckout
                              amount={19900}
                              description="Restaurant on Demand"
                              locale="auto"
                              className="checkout"
                              email="support@dinereserve.com"
                              name="DineReserve"
                              stripeKey="pk_test_SuRxpCfO2ddC7xFgx1UEEMNJ"
                              token={(token, addresses) => onToken(token, addresses)}
                              label="Pay Signup Fee"
                              allowRememberMe={false}
                            /> <a href="/signup">Reset</a></div> :
                            <Button block type="submit">Signup</Button> }
                    </Form>
                </div>
            </div>
            <div className='col-md-4'></div>
           </div>
            <AdminFooter />
        </div>
    );
}

export default AdminSignup;
