import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_ERROR
} from './AdminLoginConstants'
import {config} from '../../config'

export const adminLoginRequest = (login_info) =>{
  return{
    type : ADMIN_LOGIN_REQUEST,
    payload : login_info
  }
}

export const adminLoginSuccess = (login_success) =>{
  return{
    type : ADMIN_LOGIN_SUCCESS,
    payload : login_success
  }
}

export const adminLoginFaliure = (error) =>{
  return{
    type : ADMIN_LOGIN_ERROR,
    payload : error
  }
}

export const adminLogin = (login_info) =>{
    return(dispatch) => {
      dispatch(adminLoginRequest(login_info))
      const url =`${config.api_root}/login`;
      const request_option = {
      method: "POST",
      body: JSON.stringify(login_info),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(login_res =>{
      const login_success = login_res
      console.log('login_res', login_res);
      if(login_res && login_res.token && login_res.token.token) {
      sessionStorage.setItem("admin_login_token",login_res.token.token)
    }
    if(login_res && login_res.user && login_res.user.id ) {
      sessionStorage.setItem("user_id",login_res.user.id)
    }

      dispatch(adminLoginSuccess(login_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(adminLoginFaliure(errorMsg))
    })
    }
}
