import React ,{useEffect,useState,useMemo} from 'react'
import {
  Table,
  Button,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import AdminHeader from './AdminHeader'
import AdminFooter from './AdminFooter'
import {fetchRestaurant} from '../../Redux/HomeFeature/HomeFeatureActions'
import {fetchAllBookings} from '../../Redux/Admin/BookingList/BookingListActions'
import {fetchParticularBooking} from '../../Redux/Admin/BookingList/BookingListActions'
import { useSelector,useDispatch } from 'react-redux'

function BookingList(){
  const feature_restaurants = useSelector(state =>state.All_Restaurant)
  const booking_info = useSelector(state =>state.BookingList)
  console.log("booking_info",booking_info);
  const dispatch = useDispatch();
  const [allBookingsInfo,setAllBookingsInfo] = useState([])
  const [particularBookingsInfo,setparticularBookingsInfo] = useState([])
  const [featureRestaurant,setFeatureRestaurant] = useState([])
  const [selectedRest,setSelectedRest] = useState(null)
  const [resId,setResId] = useState(null)

  useEffect(() =>{
    dispatch(fetchRestaurant());
    dispatch(fetchAllBookings());
  },[dispatch])

  useMemo(() =>{
    setFeatureRestaurant(feature_restaurants.restaurants.All_restaurant)

  },[feature_restaurants.restaurants])

  useMemo(() =>{
    setAllBookingsInfo(booking_info.all_booking.AllBookings)

  },[booking_info.all_booking])

  useEffect(() =>{
    if(resId && resId != "null"){
      dispatch(fetchParticularBooking(resId));
    }
  },[resId])

  useEffect(() =>{
    if(resId && resId == "null"){
      //dispatch(fetchAllBookings());
    }
  },[resId])

  useMemo(() =>{
    setparticularBookingsInfo(booking_info.particular_booking.ParticularBooking)

  },[booking_info.particular_booking])

  const orgSelectFieldChange = (e) =>{
    setResId(e.target.value)

  }

  const all_Restaurant = featureRestaurant && featureRestaurant.length > 0 ? featureRestaurant.filter((items) => items.user_id === sessionStorage.getItem("user_id")).map(
      (item, i) => (
        <option key={i} value={item.id} name={item.restaurant_name}>
          {item.restaurant_name}
        </option>
      )
    ):null;

    const all_Restaurant2 = featureRestaurant && featureRestaurant.length > 0 ? featureRestaurant.map(
        (item, i) => (
          <option key={i} value={item.id} name={item.restaurant_name}>
            {item.restaurant_name}
          </option>
        )
      ):null;


    const bookingList =
      <Table>
        <tbody>
        <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Restaurant Name</th>
        <th>Booking Date</th>
        <th>Booking Time</th>
        <th>Number of Guests</th>
        <th>Total Payment</th>
          <th>Booking Status</th>

        </tr>
          {resId != null && particularBookingsInfo && particularBookingsInfo.length > 0 ? particularBookingsInfo.map(item => {
            return (
              <tr>
                <td>{item.first_name} {item.last_name}</td>
                <td>{item.email_id}</td>
                <td>{item.restaurant_name}</td>
                <td>{item.booking_date}</td>
                <td>{item.booking_time}</td>
                <td>{item.people}</td>
                <td>${item.booking_amount}</td>
                <td>{item.booking_status}</td>

              </tr>
            );
          }) : null}
        </tbody>
      </Table>

  return(
    <>
    <div className="admin_body">
      <AdminHeader />
        <div className="row">
          <div className="col-md-3">{""}</div>
          <div className="col-md-6 restaurant-info">
          <FormGroup>
            <FormLabel>Select Restaurant </FormLabel>
            <FormControl
              required
              type="text"
              onChange={e => orgSelectFieldChange(e)}
              as="select"
            >
              <option value="null">All Restaurants</option>
            {sessionStorage.getItem("user_id") == '41' ? all_Restaurant2 : all_Restaurant}
            </FormControl>
          </FormGroup>
            {allBookingsInfo && allBookingsInfo.length > 0 ? (
              <div>
                <h1>Bookings List</h1>
                <hr></hr>
                {bookingList}

              </div>
            ) : (
              <div className="emptyData"></div>
            )}
          </div>
          <div className="col-md-3">{""}</div>
        </div>
    </div>
    <AdminFooter />
  </>
  )
}

export default BookingList
