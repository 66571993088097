import React from 'react'

function FooterBottom(){
  return(
    <div className="copy">
  
      </div>
  )
}

export default FooterBottom
