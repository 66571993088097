import React ,{useEffect,useState,useMemo} from 'react'
import AdminFooter from './AdminFooter'
import AdminHeader from './AdminHeader'
import {adminLogin} from '../../Redux/Admin/AdminLogin/AdminLoginActions'
import { useSelector,useDispatch } from 'react-redux'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Redirect } from "react-router-dom";

function AdminLogin(){
  const admin_login = useSelector(state => state.AdminLogin)
  const [inputValue,setInputValue] = useState({email:'',password : ''})
  const [error,setError] = useState(null)
  const dispatch = useDispatch()
  console.log("admin_login",error);

  useMemo(() =>{
    if(admin_login.login.error){
      setError(admin_login.login.error)
    }
  },[admin_login.login.error])


  const handleSubmit = e => {
        e.preventDefault();
        const login_info = {
            email: inputValue.email,
            password: inputValue.password
        };
        dispatch(adminLogin(login_info))
        console.log("admin_login.success",admin_login.success)

    };
    if(admin_login.login.status === true || sessionStorage.getItem("admin_login_token") != null){
      window.location.href = '/admin/restaurantlist';
    }
  return(
    <div className='container-fluid'>
                <AdminHeader />
                <div className='row adminLogin'>
                    <div className='col-md-4'>
                    </div>
                    <div className='col-md-4'>
                      <div className='login-page' />
                      <div className="Login">
                          {error == null ? null : (
                              <h4 className="login-error">*{error}</h4>
                            )  }
                          <Form onSubmit={(e) => handleSubmit(e)}>
                              <Form.Group controlId="email">
                               <Form.Label> Email address </Form.Label>
                                  <Form.Control
                                      autoFocus
                                      type="email"
                                      value={inputValue.email}
                                      onChange={(e) =>setInputValue({...inputValue,email:e.target.value})}
                                  />
                              </Form.Group>
                              <Form.Group controlId="password" >
                              <Form.Label> Password </Form.Label>
                                  <Form.Control
                                      value={inputValue.password}
                                      onChange={(e) =>setInputValue({...inputValue,password:e.target.value})}
                                      type="password"
                                  />
                              </Form.Group>
                              <Button
                                  block
                                  type="submit"
                              >
                                  Login
                              </Button>
                          </Form>

                      </div>





                    </div>
                    <div className='col-md-4'>
                    </div>
                </div>
                <AdminFooter />
            </div>
  )
}

export default AdminLogin
