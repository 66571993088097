import {combineReducers} from 'redux';
import HomeFeatureReducers from './HomeFeature/HomeFeatureReducers'
import ContactReducers from './ContactUs/ContactReducers'
import SearchFormReducers from './SearchForm/SearchFormReducers'
import BookTableReducers from './BookTable/BookTableReducers'
import RestaurantCouponReducers from './RestaurantCoupons/RestaurantCouponReducers'
import AdminLoginReducers from './Admin/AdminLogin/AdminLoginReducers'
import AdminSignupReducers from './Admin/AdminSignup/AdminSignupReducers'
import RestaurantListReducers from './Admin/RestaurantList/RestaurantListReducers'
import BookingListReducers from './Admin/BookingList/BookingListReducers'
import AddCouponReducers from './Admin/AddCoupon/AddCouponReducers'
import UpdateCouponReducers from './Admin/UpdateCoupon/UpdateCouponReducers'
import UpdateBookingReducers from './UpdateBooking/UpdateBookingReducers'


const rootReducer = combineReducers({
    All_Restaurant : HomeFeatureReducers,
    Contact : ContactReducers,
    Search : SearchFormReducers,
    Book_Table : BookTableReducers,
    Restaurant_Coupons : RestaurantCouponReducers,
    AdminLogin : AdminLoginReducers,
    AdminSignup : AdminSignupReducers,
    RestaurantList : RestaurantListReducers,
    BookingList : BookingListReducers,
    Add_Coupon : AddCouponReducers,
    UpdateCoupon:UpdateCouponReducers,
    BookingUpdate:UpdateBookingReducers
})

export default rootReducer
