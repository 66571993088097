import React from 'react'
import { Link } from "react-router-dom";
import '../../Styles/index.css';

function AdminHeader(){
  const logout = (e) =>{
    e.preventDefault();
    sessionStorage.removeItem('admin_login_token');
    sessionStorage.removeItem('user_id');
    window.location.href = "/admin/admin_login";
  }
  const userLinks = (
    <ul className="menu-link">
    <li>
      <Link to="/admin/restaurantlist">
        <span className="admin_detail-link-button">Restaurantlist List</span>
      </Link>
    </li>

      <li>
        <Link to="/admin/managecoupons">
          <span className="admin_detail-link-button">Manage Coupons</span>
        </Link>
      </li>
      <li>
        <Link to="/admin/bookinglist">
          <span className="admin_detail-link-button">Bookings List</span>
        </Link>
      </li>
      <li>
        <span
          className="admin_detail-link-button"
          onClick={e => logout(e)}
        >
          LogOut
        </span>
      </li>
      {/*<li><Link to="/profile">Profile</Link></li>*/}
      {/*<li><Link to="/emailbuilder">Email Editor</Link></li>*/}
      {/*<li><Link to="/emailbuilder_list">Email Editor List</Link></li>*/}
    </ul>
  );

  const guestLinks = (
    <ul className="menu-link">
      <li>
        <Link to="/admin/admin_login">Login</Link>
      </li>

    </ul>
  );
  return(
    <div className="row admin_header">
  <div class="container">
    <div className="header-logo col-md-2">
      <Link className="admin-brand" to={"/"}>
        <img src="../../img/logo.png" alt="logo" />
      </Link>
    </div>
    <div className="header-menu col-md-10">
      {sessionStorage.getItem("admin_login_token") !== null
        ? userLinks
        : guestLinks}
    </div>
    </div>
  </div>
  )
}

export default AdminHeader
