import {
  FETCH_PARTICULAR_BOOKING_REQUEST,
  FETCH_PARTICULAR_BOOKING_SUCCESS,
  FETCH_PARTICULAR_BOOKING_FALIURE,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR
} from './UpdateBookingConstants'
import {config} from '../config'

export const fetchParticularBookingRequest = (booking_id) =>{
  return{
    type : FETCH_PARTICULAR_BOOKING_REQUEST,
    payload : booking_id
  }
}

export const fetchParticularBookingSuccess = (particular_booking_success) =>{
  return{
    type : FETCH_PARTICULAR_BOOKING_SUCCESS,
    payload : particular_booking_success
  }
}

export const fetchParticularBookingFaliure = (error) =>{
  return{
    type : FETCH_PARTICULAR_BOOKING_FALIURE,
    payload : error
  }
}

export const fetchParticularBooking = (booking_id) =>{
    return(dispatch) => {
      dispatch(fetchParticularBookingRequest(booking_id))
      const url =`${config.api_root}/booking/${booking_id}`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(particular_booking_res =>{
      const particular_booking_success = particular_booking_res
      dispatch(fetchParticularBookingSuccess(particular_booking_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchParticularBookingFaliure(errorMsg))
    })
    }
}

export const updateBookingRequest = (data,id) =>{
  return{
    type : UPDATE_BOOKING_REQUEST,
    payload : data,
    booking_id : id
  }
}

export const updateBookingSuccess = (update_booking_success) =>{
  return{
    type : UPDATE_BOOKING_SUCCESS,
    payload : update_booking_success
  }
}

export const updateBookingFaliure = (error) =>{
  return{
    type : UPDATE_BOOKING_ERROR,
    payload : error
  }
}

export const approveBooking = (id) =>{
    return(dispatch) => {
      dispatch(updateBookingRequest(id))
      const url =`${config.api_root}/booking_approve/${id.booking_token}`;
      const request_option = {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(update_booking_res =>{
      const update_booking_success = update_booking_res
      dispatch(updateBookingSuccess(update_booking_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(updateBookingFaliure(errorMsg))
    })
    }
}
export const rejectBooking = (id) =>{
    return(dispatch) => {
      dispatch(updateBookingRequest(id))
      const url =`${config.api_root}/booking_reject/${id.booking_token}`;
      const request_option = {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(update_booking_res =>{
      const update_booking_success = update_booking_res
      dispatch(updateBookingSuccess(update_booking_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(updateBookingFaliure(errorMsg))
    })
    }
}

export const trackBooking = (id) =>{
    return(dispatch) => {
      dispatch(updateBookingRequest(id))
      const url =`${config.api_root}/booking_tracker/${id.booking_token}`;
      const request_option = {
      method: "GET",
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(update_booking_res =>{
      const update_booking_success = update_booking_res
      dispatch(updateBookingSuccess(update_booking_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(updateBookingFaliure(errorMsg))
    })
    }
}
