import {
  FETCH_PARTICULAR_BOOKING_REQUEST,
  FETCH_PARTICULAR_BOOKING_SUCCESS,
  FETCH_PARTICULAR_BOOKING_FALIURE,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR
} from './UpdateBookingConstants'

const intialstate = {
  particular_booking_loading : false,
  particular_booking : [],
  particular_booking_error : '',
  particular_booking_success : false,
  update_booking_loading : false,
  update_booking : [],
  update_booking_error : '',
  update_booking_success : false,
}

const UpdateCouponReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_PARTICULAR_BOOKING_REQUEST : return{
      ...state,
      particular_booking_loading:true
    }
    case FETCH_PARTICULAR_BOOKING_SUCCESS : return{
      ...state,
      particular_booking_loading:false,
      particular_booking_success:true,
      particular_booking:action.payload
    }
    case FETCH_PARTICULAR_BOOKING_FALIURE : return{
      ...state,
      particular_booking_error:action.payload
    }
    case UPDATE_BOOKING_REQUEST : return{
      ...state,
      update_booking_loading:true
    }
    case UPDATE_BOOKING_SUCCESS : return{
      ...state,
      update_booking_loading:false,
      update_booking_success:true,
      update_booking:action.payload
    }
    case UPDATE_BOOKING_ERROR : return{
      ...state,
      update_booking_error:action.payload
    }
    default: return state
  }

}

export default UpdateCouponReducers
