import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div>

                <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="footer-logo">
                <img src="/img/dinereserve-logo.png" />
              </div>
            </div>

            <div className="col-md-6 col-lg-6 mobleft">
              <div className="footer-follow">
                <h5>Follow Us on:</h5>
                <ul>
                  <li><a href="https://www.facebook.com/DiningTek/"><img src="/img/fb.png" /> Facebook</a></li>
                  <li><a href="https://mobile.twitter.com/diningtek"><img src="/img/twitter.png" /> Twitter</a></li>
                  <li><a href="https://www.linkedin.com/company/diningtek"><img src="/img/linkedin.png" /> Linkedin</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mobright">
              <div className="footer-address">
                <p><img src="/img/home-icon.png" /> <span>4770 Baseline Rd,<br />Suite 200 Boulder, <br />CO 80303</span></p>
                <p><img src="/img/mail-icon.png" /> <span>info@dinereserve.com</span></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-nav">
        <div className="container">
          <ul>
            <li><a href="/contactus">Contact Us</a></li>
            <li><a href="/termandcondition">Terms &amp; Conditions</a></li>
            <li><a href>© 2023 DineReserve</a></li>
          </ul>
        </div>
      </div>
                <script src="/js/jquery-3.3.1.min.js"></script>
  <script src="js/slick/slick.js" type="text/javascript" charset="utf-8"></script>


  <script src="/js/popper.js"></script>

  <script src="/js/bootstrap.js"></script>
    <script src="/js/handleCounter.js"></script>
  <script src="/js/custom.js"></script>

<script src="/js/init.js"></script>


  <a href="#" class="scrolltotop"><i class="fas fa-angle-up"></i></a>
  <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAbGLWqhmWGpFt8Ppc8WSDc8mDfyv2oI84&libraries=places"></script>

      </div>
        );
    }
}

export default Footer;
