import {
  FETCH_PARTICULAR_RESTAURANT_REQUEST,
  FETCH_PARTICULAR_RESTAURANT_SUCCESS,
  FETCH_PARTICULAR_RESTAURANT_ERROR,
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FALIURE
} from './BookTableConstants'

const intialstate = {
  loading : false,
  restaurant : [],
  restaurant_success : false,
  error : '',
  booking_error : '',
  booking_loading : false,
  bookingcompleted : false,
  success_booking_data : []
}

const BookTableReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_PARTICULAR_RESTAURANT_REQUEST : return{
      ...state,
      loading:true
    }
    case FETCH_PARTICULAR_RESTAURANT_SUCCESS : return{
      ...state,
      loading:false,
      restaurant_success:true,
      restaurant:action.payload
    }
    case FETCH_PARTICULAR_RESTAURANT_ERROR : return{
      ...state,
      error : action.payload
    }
    case CREATE_BOOKING_REQUEST : return{
      ...state,
      booking_loading:true
    }
    case CREATE_BOOKING_SUCCESS : return{
      ...state,
      booking_loading:false,
      bookingcompleted : true,
      success_booking_data:action.payload
    }
    case CREATE_BOOKING_FALIURE : return{
      ...state,
      booking_error:action.payload
    }
    default: return state
  }

}

export default BookTableReducers
