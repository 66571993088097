import {
  ADMIN_SIGNUP_REQUEST,
  ADMIN_SIGNUP_SUCCESS,
  ADMIN_SIGNUP_ERROR
} from './AdminSignupConstants'
import {config} from '../../config'

export const adminSignupRequest = (signup_info) =>{
  return{
    type : ADMIN_SIGNUP_REQUEST,
    payload : signup_info
  }
}

export const adminSignupSuccess = (signup_success) =>{
  return{
    type : ADMIN_SIGNUP_SUCCESS,
    payload : signup_success
  }
}

export const adminSignupFaliure = (error) =>{
  return{
    type : ADMIN_SIGNUP_ERROR,
    payload : error
  }
}

export const adminSignup = (signup_info) =>{
    return(dispatch) => {
      dispatch(adminSignupRequest(signup_info))
      const url =`${config.api_root}/register`;
      const request_option = {
      method: "POST",
      body: JSON.stringify(signup_info),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(signup_res =>{
      const signup_success = signup_res
      dispatch(adminSignupSuccess(signup_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(adminSignupFaliure(errorMsg))
    })
    }
}
