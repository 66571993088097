import {
  FETCH_PARTICULAR_RESTAURANT_REQUEST,
  FETCH_PARTICULAR_RESTAURANT_SUCCESS,
  FETCH_PARTICULAR_RESTAURANT_ERROR,
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FALIURE
} from './BookTableConstants'
import {config} from '../config'

export const fetchParticularRestaurantRequest = (id) =>{
  return{
    type : FETCH_PARTICULAR_RESTAURANT_REQUEST,
    payload : id
  }
}

export const fetchParticularRestaurantSuccess = (restaurant) =>{
  return{
    type : FETCH_PARTICULAR_RESTAURANT_SUCCESS,
    payload : restaurant
  }
}

export const fetchParticularRestaurantFailure = (error) =>{
  return{
    type : FETCH_PARTICULAR_RESTAURANT_ERROR,
    payload : error
  }
}

export const fetchParticularRestaurant = (id) =>{
    return(dispatch) => {
      dispatch(fetchParticularRestaurantRequest(id))
      const url =`${config.api_root}/restaurantslug/${id}`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(restaurant_info =>{
      const restaurant = restaurant_info
      dispatch(fetchParticularRestaurantSuccess(restaurant))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchParticularRestaurantFailure(errorMsg))
    })
    }
}

export const createBookingRequest = (booking_data) =>{
  return{
    type : CREATE_BOOKING_REQUEST,
    payload : booking_data
  }
}

export const createBookingSuccess = (success_data) =>{
  return{
    type : CREATE_BOOKING_SUCCESS,
    payload : success_data
  }
}

export const createBookingError = (error) =>{
  return{
    type : CREATE_BOOKING_FALIURE,
    payload : error
  }
}

export const createBooking = (booking_data) =>{
  console.log('restaurantPreDefinedValue_id', booking_data.rest_id );
    return(dispatch) => {
      dispatch(createBookingRequest(booking_data))
      const url =`${config.api_root}/booking_create/${booking_data.rest_id}/${booking_data.coupon_id}`;
      const request_option = {
      method: "POST",
      body: JSON.stringify({
                first_name:booking_data.first_name,
                last_name:booking_data.last_name,
                phone_number:booking_data.phone_number,
                email_id:booking_data.email_id,
                people:booking_data.people,
                location:booking_data.location,
                booking_time:booking_data.booking_time,
                booking_date:booking_data.booking_date,
                booking_amount:booking_data.booking_amount,
                payment_token:booking_data.payment_token,
                booking_status:booking_data.booking_status
              }),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(created_booking =>{
      const success_data = created_booking
      dispatch(createBookingSuccess(success_data))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(createBookingError(errorMsg))
    })
    }
}
