import {
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
  UPDATE_RESTAURANT_ERROR,
  APPROVE_RESTAURANT_REQUEST,
  APPROVE_RESTAURANT_SUCCESS,
  APPROVE_RESTAURANT_ERROR
} from './RestaurantListConstants'

const intialstate = {
  loading : false,
  update_restaurant_info : [],
  error : '',
  update_restaurant_success : false
}

const RestaurantListReducers = (state = intialstate,action) =>{
  switch(action.type){
    case UPDATE_RESTAURANT_REQUEST : return{
      ...state,
      loading:true
    }
    case UPDATE_RESTAURANT_SUCCESS : return{
      ...state,
      loading:false,
      update_restaurant_success:true,
      update_restaurant_info:action.payload
    }
    case UPDATE_RESTAURANT_ERROR : return{
      ...state,
      error:action.payload
    }
    case APPROVE_RESTAURANT_REQUEST : return{
      ...state,
      loading:false
    }
    case APPROVE_RESTAURANT_SUCCESS : return{
      ...state,
      loading:false,
      approve_restaurant_success:true,
      approve_restaurant_info:action.payload
    }
    case APPROVE_RESTAURANT_ERROR : return{
      ...state,
      error:action.payload
    }
    default: return state
  }

}

export default RestaurantListReducers
