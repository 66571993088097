import {
  FETCH_RESTAURANT_COUPONS_REQUEST,
  FETCH_RESTAURANT_COUPONS_SUCCESS,
  FETCH_RESTAURANT_COUPONS_ERROR
} from './RestaurantCouponConstants'

const intialstate = {
  loading : false,
  coupons : [],
  admin_coupons : [],
  error : ''
}

const RestaurantCouponReducers = (state = intialstate,action) =>{
  switch(action.type){
    case FETCH_RESTAURANT_COUPONS_REQUEST : return{
      ...state,
      loading:true
    }
    case FETCH_RESTAURANT_COUPONS_SUCCESS : return{
      ...state,
      loading:false,
      coupons:state.coupons.concat(action.payload),
      admin_coupons:action.payload
    }
    case FETCH_RESTAURANT_COUPONS_ERROR : return{
      ...state,
      error : action.payload
    }
    default: return state
  }

}

export default RestaurantCouponReducers
