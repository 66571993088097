import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import FooterBottom from "./FooterBottom";
import { approveBooking, rejectBooking, trackBooking } from '../Redux/UpdateBooking/UpdateBookingActions'
import { useDispatch, useSelector } from "react-redux";

function BookingTracker() {
  const [orderApproved, setOrderApproved] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const bookingUpdatesdata = useSelector(state =>state.BookingUpdate.update_booking);

  useEffect(() => {
    let search = new URLSearchParams(location.search);
    const booking_token = search.get("booking_token");
    //const status = search.get("status");

    if (location.search && booking_token != null) {
        dispatch(trackBooking({ booking_token }))
      }

    console.log(location.search);
    console.log(booking_token);
  }, [location, dispatch]);


  return (
    <div className="">
      <Header />
      {bookingUpdatesdata && !bookingUpdatesdata.status === null ? (
        <div className="order-status">
          <div className="Booking-loader">
            <img src="/img/loader.gif" alt="loader"></img>
          </div>
        </div>
      ) : (
        <div className="order-status">
          <h2>
            {bookingUpdatesdata && bookingUpdatesdata.bookingtracker ? (
              <span className="approved-order">
                Your Order is {bookingUpdatesdata.bookingtracker}
              </span>
            ) : null}

          </h2>
        </div>
      )}
      <Footer />
      <FooterBottom />
    </div>
  );
}

export default BookingTracker;
