import {
  FETCH_PARTICULAR_COUPON_REQUEST,
  FETCH_PARTICULAR_COUPON_SUCCESS,
  FETCH_PARTICULAR_COUPON_FALIURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_ERROR
} from './UpdateCouponConstants'
import {config} from '../../config'

export const fetchParticularCouponRequest = (coupon_id) =>{
  return{
    type : FETCH_PARTICULAR_COUPON_REQUEST,
    payload : coupon_id
  }
}

export const fetchParticularCouponSuccess = (particular_coupon_success) =>{
  return{
    type : FETCH_PARTICULAR_COUPON_SUCCESS,
    payload : particular_coupon_success
  }
}

export const fetchParticularCouponFaliure = (error) =>{
  return{
    type : FETCH_PARTICULAR_COUPON_FALIURE,
    payload : error
  }
}

export const fetchParticularCoupon = (coupon_id) =>{
    return(dispatch) => {
      dispatch(fetchParticularCouponRequest(coupon_id))
      const url =`${config.api_root}/coupon/${coupon_id}`;
      const request_option = {
      method: "GET"
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(particular_coupon_res =>{
      const particular_coupon_success = particular_coupon_res
      dispatch(fetchParticularCouponSuccess(particular_coupon_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(fetchParticularCouponFaliure(errorMsg))
    })
    }
}

export const updateCouponRequest = (data,id) =>{
  return{
    type : UPDATE_COUPON_REQUEST,
    payload : data,
    coupon_id : id
  }
}

export const updateCouponSuccess = (update_coupon_success) =>{
  return{
    type : UPDATE_COUPON_SUCCESS,
    payload : update_coupon_success
  }
}

export const updateCouponFaliure = (error) =>{
  return{
    type : UPDATE_COUPON_ERROR,
    payload : error
  }
}

export const updateCoupon = (data,id) =>{
    return(dispatch) => {
      dispatch(updateCouponRequest(data,id))
      const url =`${config.api_root}/couponupdate/${id}`;
      const request_option = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
                "Content-Type": "application/json"
              }
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(update_coupon_res =>{
      const update_coupon_success = update_coupon_res
      dispatch(updateCouponSuccess(update_coupon_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(updateCouponFaliure(errorMsg))
    })
    }
}
