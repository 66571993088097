import React, { useEffect, useState, useMemo } from 'react';
import Footer from './Footer';
import Header from './Header';
import { adminSignup } from '../Redux/Admin/AdminSignup/AdminSignupActions';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Redirect } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';
import FooterBottom from './FooterBottom'

function AdminSignup() {
    const admin_signup = useSelector(state => state.AdminSignup);
    const [inputValue, setInputValue] = useState({
        restaurant_name: '',
        email: '',
        password: '',
        phone_number: ''
    });
    const [error, setError] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [paymentToken, setPaymentToken] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isValidating, setIsValidating] = useState(false);
    const [infoCheckCompleted, setInfoCheckCompleted] = useState(false);
    const [regsiterSuccess, setRegsiterSuccess] = useState(false);

    // New state for payment plan selection
    const [paymentPlan, setPaymentPlan] = useState('Yearly'); // 'Yearly' as default, or you can set it to 'Monthly'

    const getStripeAmount = () => {
        return paymentPlan === 'Monthly' ? 2995 : 29900; // 29.95 for monthly and 299 for yearly (in cents)
    };

    const getStripeDescription = () => {
        return paymentPlan === 'Monthly' ? "Monthly Subscription for DineReserve" : "Yearly Subscription for DineReserve";
    };

    const dispatch = useDispatch();

    useMemo(() => {
        if (admin_signup.signup.error) {
            setError(admin_signup.signup.error);
        }
    }, [admin_signup.signup.error]);

    useMemo(() => {
        if (admin_signup.signup && admin_signup.signup.status === true) {
            setRegsiterSuccess(true);
        }
        else if(admin_signup.signup && admin_signup.signup.validation_status === true) {
          setInfoCheckCompleted(true)
        }
    }, [admin_signup.signup]);

    const onToken = (token, addresses) => {
        // Save the token and set payment as complete
        setPaymentToken(token.id);
        setPaymentComplete(true);
        const signup_info = {
            restaurant_name: inputValue.restaurant_name,
            email: inputValue.email,
            password: inputValue.password,
            phone_number: inputValue.phone_number,
            payment_token: paymentToken,
            payment_plan:paymentPlan
        };

        dispatch(adminSignup(signup_info));
    };

    const validateEmail = email => {
       const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
       return emailPattern.test(email);
   }

   const validatePhone = phone => {
       const phonePattern = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
       return phonePattern.test(phone);
   }

    const handleSubmit = e => {
        e.preventDefault();
        setError(null);
        setIsValidating(true);
        if (!validateEmail(inputValue.email)) {
            setIsEmailValid(false);
        } else {
            setIsEmailValid(true);
        }
        if (!validatePhone(inputValue.phone_number)) {
            setIsPhoneValid(false);
        } else {
            setIsPhoneValid(true);
        }
        setIsValidating(false);
        // if (!paymentComplete) {
        //     alert("Please complete the payment first.");
        //     return;
        // }
        if(validateEmail(inputValue.email) && validatePhone(inputValue.phone_number) && inputValue.restaurant_name != '' && inputValue.password != '') {
          const signup_info = {
              restaurant_name: inputValue.restaurant_name,
              email: inputValue.email,
              password: inputValue.password,
              phone_number: inputValue.phone_number,
              payment_token: paymentToken,
              validate_only:true
          };

          dispatch(adminSignup(signup_info));
          //setInfoCheckCompleted(true)
        }

    };

    if (admin_signup.signup.status === true) {
        //window.location.href = '/admin/admin_login';
      //  setRegsiterSuccess(true);
    }

    const renderError = (field) => {
        return error && error[field] ? (
            <h6 className="signup-error">
                *{JSON.stringify(error[field]).replace(/[\[\]"]+/g, "")}
            </h6>
        ) : null;
    };

    return (
      <div class ="about">
            <Header />
            <div className="row">
              <div className="container">
                <div class="row main-about">
                <div className="col-md-5 about-mission">

                    <>
  <section className="admin-signup">
    <h1>DineReserve Signup</h1>
    <p>
      At DineReserve, we pride ourselves on providing a seamless and efficient
      reservation platform for restaurants. To continue offering top-tier
      services, monthly fee <strong>$29.95</strong> per month or yearly signup fee of <strong>$299</strong> is charged to
      onboard your restaurant.
    </p>
  </section>
  <section className="why-choose">
    <h4>Why Choose DineReserve for Table Reservations?</h4>
    <ol>
      <li>
        <strong>Efficiency at its Best:</strong> Our system efficiently manages
        reservations, minimizing wait times and optimizing seating capacities,
        ensuring your establishment runs smoothly during peak hours.
      </li>
      <li>
        <strong>Enhanced Customer Experience:</strong> Give your customers the
        luxury of booking their preferred tables with just a few clicks. A
        hassle-free booking experience can significantly elevate their overall
        dining experience.
      </li>

      <li>
        <strong>Promotions and Specials:</strong> Promote special events,
        discounts, or new menu items directly through our platform, ensuring
        your regulars and new customers are always in the loop.
      </li>
    </ol>
    <p>
      Join the DineReserve family today and elevate your restaurant's
      reservation management to global standards.
    </p>
  </section>
</>


                </div>
                  <div className="col-md-7 about-story">
                  {!regsiterSuccess ?  (<div className="Login">
                 {!isEmailValid ? <p className="email_error error">Invalid Email format</p> : null}
                 {!isPhoneValid ? <p className="email_error error">Invalid Phone Number format</p> : null}
                     <Form onSubmit={(e) => handleSubmit(e)}>

                     <Form.Group controlId="paymentPlan">
                            <Form.Label>Payment Plan</Form.Label>
                            <Form.Control as="select" value={paymentPlan}
                            //disabled={infoCheckCompleted ? true : false}
                            onChange={e => {setPaymentPlan(e.target.value);setInfoCheckCompleted(false);}}>
                                <option value="Monthly">Monthly - $29.95</option>
                                <option value="Yearly">Yearly - $299</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="restaurant_name">
                            <Form.Label>Restaurant Name</Form.Label>
                            <Form.Control
                                autoFocus
                                type="text"
                                required
                                //disabled = {infoCheckCompleted ? true : false}
                                value={inputValue.restaurant_name}
                                onChange={e => {setInputValue({ ...inputValue, restaurant_name: e.target.value });setInfoCheckCompleted(false);}}
                            />
                            {renderError('restaurant_name')}
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                value={inputValue.email}
                                //disabled = {infoCheckCompleted ? true : false}
                                onChange={e => {setInputValue({ ...inputValue, email: e.target.value });setInfoCheckCompleted(false);}}
                            />
                            {renderError('email')}
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                value={inputValue.password}
                              //disabled = {infoCheckCompleted ? true : false}
                                onChange={e => {setInputValue({ ...inputValue, password: e.target.value });setInfoCheckCompleted(false);}}
                            />
                            {renderError('password')}
                        </Form.Group>

                        <Form.Group controlId="phone_number">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={inputValue.phone_number}
                                //disabled = {infoCheckCompleted ? true : false}
                                onChange={e => {setInputValue({ ...inputValue, phone_number: e.target.value });setInfoCheckCompleted(false);}}
                            />
                            {renderError('phone_number')}
                        </Form.Group>

                        {infoCheckCompleted ?
                    <div className="pymntbtn">
                        <StripeCheckout
                            amount={getStripeAmount()} // Dynamic amount based on the payment plan
                            description={getStripeDescription()} // Dynamic description based on the payment plan
                            locale="auto"
                            className="checkout"
                            email="support@dinereserve.com"
                            name="DineReserve"
                            stripeKey="pk_test_SuRxpCfO2ddC7xFgx1UEEMNJ"
                            token={(token, addresses) => onToken(token, addresses)}
                            label="Pay Signup Fee"
                            allowRememberMe={false}
                        />
                        <a href="/signup">Reset</a>
                    </div>
                :
                    <Button block type="submit">Signup</Button>
                }
            </Form>
                </div>) : null}
                {regsiterSuccess ? (<div className="Login success-signup">Congrats! <br/> You have Signed up Successfully <br/>
                Please <a href="/admin/admin_login">click here</a> to login to your dashboard.</div> ): null}
                </div>

              </div>
            </div>
          </div>
          <Footer />
          <FooterBottom />

      </div>
    );
}

export default AdminSignup;
