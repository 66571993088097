import React ,{useEffect,useState,useMemo} from 'react'
import AdminHeader from './AdminHeader'
import AdminFooter from './AdminFooter'
import {
  Table,
  Button,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import {fetchRestaurant} from '../../Redux/HomeFeature/HomeFeatureActions'
import {fetchRestaurantCoupons} from '../../Redux/RestaurantCoupons/RestaurantCouponActions'
import {fetchParticularCoupon} from '../../Redux/Admin/UpdateCoupon/UpdateCouponActions'
import {updateCoupon} from '../../Redux/Admin/UpdateCoupon/UpdateCouponActions'
import { useSelector,useDispatch } from 'react-redux'

function UpdateCoupon(){
  const feature_restaurants = useSelector(state =>state.All_Restaurant)
  const restaurant_coupons = useSelector(state =>state.Restaurant_Coupons)
  const update_coupons = useSelector(state =>state.UpdateCoupon)
  const dispatch = useDispatch();
  const [featureRestaurant,setFeatureRestaurant] = useState([])
  const [selectedRest,setSelectedRest] = useState(null)
  const [resId,setResId] = useState("null")
  const [couponId,setCouponId] = useState("null")
  const [coupons,setCoupons] = useState([])
  console.log("coupons",coupons);
  const [particularCoupons,setParticularCoupons] = useState([])
  const [addresponse,setAddresponse] = useState(null)
  const [inputValues,setInputvalues] = useState({coupon_name:'',
                                              coupon_value:'',
                                              coupon_price:'',
                                              coupon_code:'',
                                              coupon_expiry:'',
                                              coupon_status:''
                                            })

  useEffect(() =>{
    dispatch(fetchRestaurant());
  },[dispatch])

  useMemo(() =>{
    setFeatureRestaurant(feature_restaurants.restaurants.All_restaurant)

  },[feature_restaurants.restaurants])

  useEffect(() =>{
    if(resId && resId != "null"){
      dispatch(fetchRestaurantCoupons(resId));
    }
  },[resId])

  useMemo(() =>{
    setCoupons(restaurant_coupons.admin_coupons.All_coupon)
  },[restaurant_coupons.admin_coupons])

  useEffect(() =>{
    if(couponId && couponId != "null"){
      dispatch(fetchParticularCoupon(couponId));
    }
  },[couponId])

  useMemo(() =>{
    setParticularCoupons(update_coupons.particular_coupon.coupon)

  },[update_coupons.particular_coupon])

  useMemo(() =>{
    if(particularCoupons && particularCoupons.length > 0){
      particularCoupons.map(item =>{
        setInputvalues({
          coupon_name:item.coupon_name,
          coupon_value : item.coupon_value,
          coupon_price : item.coupon_price,
          coupon_code : item.coupon_code,
          coupon_expiry : item.coupon_expiry,
          coupon_status : item.coupon_status

        })

      })
    }

  },[particularCoupons])

    const orgSelectFieldChange = (e) =>{
      setResId(e.target.value)

    }

    const orgSelectCouponFieldChange = (e) =>{
      setCouponId(e.target.value)

    }


  const updateCouponhandler = () =>{
    const data = inputValues;
    dispatch(updateCoupon(data,couponId))
  }




  useMemo(() =>{
    if(update_coupons && update_coupons.update_coupon_success === true){
      setAddresponse(true)
      const timer = setTimeout(() => {
          window.location.reload();
      }, 1000);
    }
  },[update_coupons])
  useMemo(() =>{
    if(update_coupons && update_coupons.update_coupon_loading === true){
      setAddresponse(100)
    }
  },[update_coupons])
  const all_coupons = coupons && coupons.length > 0 ? coupons.map(
      (item, i) => (
        <option key={i} value={item.id}>
          {item.coupon_name}
        </option>
      )
    ):null;

    const all_Restaurant = featureRestaurant && featureRestaurant.length > 0 ? featureRestaurant.filter((items) => items.user_id === sessionStorage.getItem("user_id")).map(
        (item, i) => (
          <option key={i} value={item.id} name={item.restaurant_name}>
            {item.restaurant_name}
          </option>
        )
      ):null;

      const all_Restaurant2 = featureRestaurant && featureRestaurant.length > 0 ? featureRestaurant.map(
          (item, i) => (
            <option key={i} value={item.id} name={item.restaurant_name}>
              {item.restaurant_name}
            </option>
          )
        ):null;

    const responseArea = addresponse === 100 ? (
      <div className="ResturantInfo-loader">
        <img src="/img/loadernew.gif"></img>
      </div>
    ) : (
      <div className="RestaurantInfo-confrmation">
        <h2 className="RestaurantInfo-confrmation-heading">Thank You!</h2>
        <h2>Coupon Update Successfully!</h2>
      </div>
    );
  return(
    <>
    <div className="admin_body">
    <AdminHeader />
    {addresponse === null ?
      (<div className="row">
        <div className="col-md-3">{""}</div>
        <div className="col-md-6 restaurant-info">
        <FormGroup>
          <FormLabel>Select Restaurant </FormLabel>
          <FormControl
            required
            type="text"
            onChange={e => orgSelectFieldChange(e)}
            as="select"
          >
            <option value= "null">Select Restaurant</option>
            {sessionStorage.getItem("user_id") == '41' ? all_Restaurant2 : all_Restaurant}
          </FormControl>
        </FormGroup>
        {resId != "null" ? (
        <FormGroup>
          <FormLabel>Select Coupon</FormLabel>
          <FormControl
            required
            type="text"
            onChange={e => orgSelectCouponFieldChange(e)}
            as="select"
          >
            <option value= "null">Select Coupon</option>
            {all_coupons}
          </FormControl>
        </FormGroup>) : null}
        {couponId != "null"   ?
          (<div><h1>Coupon Information</h1>
          <hr></hr>
          <form action="" onSubmit={(e) =>updateCouponhandler(e)}>
            <Table>
              <tbody>
              <tr>
                <td>
                <FormGroup>
                  <FormLabel>Restaurant ID</FormLabel>
                  <FormControl
                    required
                    type="text"
                    disabled
                    value={resId}
                  />
                </FormGroup>
                </td>
                <td>
                <FormGroup>
                  <FormLabel>Coupon ID</FormLabel>
                  <FormControl
                    required
                    type="text"
                    disabled
                    value={couponId}
                  />
                </FormGroup>
                </td>
              </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Coupon Name</FormLabel>
                    <FormControl
                      required
                      type="text"
                      onChange={e =>
                        setInputvalues({...inputValues,coupon_name:e.target.value})
                      }
                      value={inputValues.coupon_name}
                    />
                  </FormGroup>
                </td>

                  <td>
                    <FormGroup>
                      <FormLabel>Coupon Value</FormLabel>
                      <FormControl
                        required
                        type="number"
                        onChange={e =>
                          setInputvalues({...inputValues,coupon_value:e.target.value})
                        }
                        value={inputValues.coupon_value}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Coupon Price</FormLabel>
                    <FormControl
                      required
                      type="number"
                      onChange={e =>
                        setInputvalues({...inputValues,coupon_price:e.target.value})
                      }
                      value={inputValues.coupon_price}
                    />
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Coupon Code</FormLabel>
                      <FormControl
                        required
                        type="text"
                        onChange={e =>
                          setInputvalues({...inputValues,coupon_code:e.target.value})
                        }
                        value={inputValues.coupon_code}
                      />
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                <td>
                  <FormGroup>
                    <FormLabel>Coupon Expiry</FormLabel>
                    <FormControl
                      required
                      type="date"
                      onChange={e =>
                        setInputvalues({...inputValues,coupon_expiry:e.target.value})
                      }
                      value={inputValues.coupon_expiry}
                    >

                    </FormControl>
                  </FormGroup>
                </td>
                  <td>
                    <FormGroup>
                      <FormLabel>Coupon Status</FormLabel>
                      <FormControl
                        required
                        type="text"
                        onChange={e =>
                          setInputvalues({...inputValues,coupon_status:e.target.value})
                        }
                        value={inputValues.coupon_status}
                        as="select"
                      >
                        <option value="">Select Coupon Status</option>
                        <option value="True">True</option>
                        <option value="False">False</option>
                        </FormControl>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td colSpan="12">
                    <Button type="submit" className="org-btn-create">
                      Update Coupon
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </form></div>)
         : <div className="emptyData"></div>}
        </div>
        <div className="col-md-3">{""}</div>
      </div>):responseArea}

    </div>
    <AdminFooter />
    </>
  )
}

export default UpdateCoupon
