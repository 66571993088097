import React ,{useState,useEffect,useMemo} from 'react'
import Header from './Header'
import Footer from './Footer'
import FooterBottom from './FooterBottom'
import SearchForm from './SearchForm'
import { useSelector,useDispatch } from 'react-redux'
import {fetchRestaurant} from '../Redux/HomeFeature/HomeFeatureActions'
import {fetchRestaurantCoupons} from '../Redux/RestaurantCoupons/RestaurantCouponActions'
import { Link } from 'react-router-dom';

function Restaurants(){
  const feature_restaurants = useSelector(state =>state.All_Restaurant)
  console.log("feature_restaurants",feature_restaurants);
  const restaurant_coupons = useSelector(state =>state.Restaurant_Coupons)
  console.log("restaurant_coupons",restaurant_coupons);
  const [featureRestaurant,setFeatureRestaurant] = useState([])
  const [coupons,setCoupons] = useState([])
  console.log("coupons",coupons);
  const dispatch = useDispatch();
  // Hooks start
  useEffect(() =>{
    dispatch(fetchRestaurant());
  },[dispatch])

  useMemo(() =>{
    setFeatureRestaurant(feature_restaurants.restaurants.All_restaurant)
  },[feature_restaurants.restaurants])

  useMemo(() =>{
    if(featureRestaurant && featureRestaurant.length > 0){
      featureRestaurant.filter(item => item.booking_option != 'False').map((data, i) =>{
        dispatch(fetchRestaurantCoupons(data.id));
      } )
    }

  },[featureRestaurant])

  useMemo(() =>{
  setCoupons(restaurant_coupons.coupons)

  },[restaurant_coupons.coupons])

  // Hooks end
  return(
    <>
      <Header />
      <div className = "search-form listrs">
      <SearchForm />
      </div>
      <div className="book-table-main">
      <div className="srating">
        <div className="container">
          <div className="srating-wrapper">
            <div className="row">
            {featureRestaurant
              ? featureRestaurant.filter(item => item.booking_option != 'False' && item.admin_approval != 0).map((data, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="b-page-one">
                  <div className="b-page-one-wrapper">
                    <div className="b-page-one-img">
                      <img src={data.logo_image} alt="images not found" />
                    </div>
                    <div className="b-page-one-para">
                      <h4>{data.restaurant_name}</h4>
                      <p className="restdes">{data.restaurant_description}
                      </p>
                    </div>
                    <div className="b-page-one-rating">
                      <h5>4.5</h5>
                      <p>20 reviews</p>
                    </div>
                  </div>
                  <div className="b-page-one-wrapper-address">
                  <img src="./img/map-pin.png" alt="images not found" />
                    <h6>{data.restaurant_address}</h6>
                  </div>

                    <div className="b-page-link text-center">
                    <Link  to={{ pathname:`/booktable/${data.restaurant_slug}`,  data: data }}>
                        Book Your Table{" "}
                        <span>
                          <i className="fas fa-angle-right" />
                        </span>
                      </Link>
                    </div>
                  
                </div>
                 {data.coupons && data.coupons.length > 0 ? (
                   <ul className="coupon-code">
                     <li>
                       <div className="row">
                         <div className="col-md-2">
                           <img className="coupon-img" src="/img/coupn-logo.png" alt="Coupon" />
                         </div>
                         <div className="col-md-6">
                           <p>{data.coupons[0].coupon_name}</p>
                         </div>
                         <div className="col-md-4">
                           <Link to={{ pathname: `/booktable/${data.restaurant_slug}` }}>
                             Book Now{" "}
                           </Link>
                         </div>
                       </div>
                     </li>
                   </ul>
                 ) : null}




              </div>
              )): (<div className="rest-loader">
              <img src="/img/loader.gif"/></div>)}
              </div>
              </div>
            </div>
          </div>
      <div className="spagi">
        <div className="container">
          <div className="spagi-wrapper text-right">
            <div className="spagi-right">


            </div>
          </div>
        </div>
      </div>
    </div>
      <Footer />
      <FooterBottom />
    </>
  )
}

export default Restaurants
